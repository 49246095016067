// export const CMS_ENDPOINT = "https://api.coophub.co";
// export const WEB_ENDPOINT = "https://app.coophub.co"





export const WEB_ENDPOINT = "https://app-test.coophub.co"
export const CMS_ENDPOINT = "https://api-test.coophub.co"






// export const CMS_ENDPOINT = "https://api-test.coophub.co"
// export const WEB_ENDPOINT = "http://localhost:5173"





export const countries_numbers = [
    { value: "+1", label: "🇺🇸" },
    { value: "+44", label: "🇬🇧" },
    { value: "+33", label: "🇫🇷" },
    { value: "+49", label: "🇩🇪" },
    { value: "+81", label: "🇯🇵" },
    { value: "+86", label: "🇨🇳" },
    { value: "+91", label: "🇮🇳" },
    { value: "+61", label: "🇦🇺" },
    { value: "+7", label: "🇷🇺" },
    { value: "+55", label: "🇧🇷" },
    { value: "+20", label: "🇪🇬" },
    { value: "+966", label: "🇸🇦" },
    { value: "+971", label: "🇦🇪" },
    { value: "+962", label: "🇯🇴" },
    { value: "+965", label: "🇰🇼" },
    { value: "+961", label: "🇱🇧" },
    { value: "+964", label: "🇮🇶" },
    { value: "+973", label: "🇧🇭" },
    { value: "+968", label: "🇴🇲" },
    { value: "+974", label: "🇶🇦" },
    { value: "+52", label: "🇲🇽" },
    { value: "+45", label: "🇩🇰" },
    { value: "+64", label: "🇳🇿" },
    { value: "+34", label: "🇪🇸" },
    { value: "+39", label: "🇮🇹" },
    { value: "+41", label: "🇨🇭" },
  ]