import { hasLength, isEmail, isNotEmpty, useForm } from "@mantine/form";
import { tn, vtn } from "../../../../../translation";
import {
  Button,
  NativeSelect,
  Select,
  Text,
  TextInput,
  Transition,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../context/TranslationContext";
import { CMS_ENDPOINT, countries_numbers } from "../../../../../constants";
import PocketBase from "pocketbase";
import { UserContext } from "../../../../../context/UserContext";
import { AppContext } from "../../../../../context/AppContext";
import { vf } from "../../../../Functions";
const pb = new PocketBase(CMS_ENDPOINT);
export const AdminAddApplicants = ({ data, onClose }: any) => {
  const [isErrorE, setisErrorE]: any = useState(false);

  const { translation, locale }: any = useContext(TranslationContext);
  const { reloader, setReloader }: any = useContext(AppContext);
  const roles: any = vf("roles");
  const [countryNum, setCountryNum] = useState("+966");
  const [mobile, setMobile] = useState("");
  const { company }: any = useContext(UserContext);
 const emailsError = [
  vtn("email"),
  vtn("taken_email")
 ]
 const form: any = useForm({
  initialValues: {
    first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      transfer_no: "",
      department: "",
      premmissions: "",
  },

  validate: {
    first_name: isNotEmpty(vtn("empty")),
      last_name: isNotEmpty(vtn("empty")),
      email: (value) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? isErrorE
          ? emailsError[1]
          : null
        :  emailsError?.[0],
      mobile: hasLength(
        { min: countryNum?.length + 9, max: countryNum?.length + 9 },
        vtn("mobile")
      ),
      transfer_no: isNotEmpty(vtn("empty")),
      // department:isNotEmpty(vtn("empty")),
      premmissions: isNotEmpty(vtn("empty")),
  },
});

  useEffect(() => {
    form.setFieldValue("mobile", countryNum + mobile);
  }, [mobile, countryNum]);
  useEffect(() => {
    const resultList = pb
      .collection("recruiters")
      .getList()
      .then((res: any) => {
        if (
          res.items.filter((item: any) => item.email == form.values.email)
            .length === 0
        ) {
          setisErrorE(false);
        } else {
          setisErrorE(true);
        }
      });
  }, [form.values.email]);
  return (
    <form
      style={{
        width: "100%",
      }}
      onSubmit={form?.onSubmit(async (value) => {
        const data = {
          email: value?.email,
          emailVisibility: true,
          password: "12345678",
          passwordConfirm: "12345678",
          name: value?.first_name + " " + value?.last_name,
          mobile: value?.mobile,
          company: company?.id,
          role: value?.premmissions,
          status: "active",
        };

        const record = await pb
          .collection("recruiters")
          .create(data)
          .then((records: any) => {
            setReloader(!reloader);
            onClose({});
          });
      })}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            width: "100%",
            padding: "20px",
            color: "rgba(0, 0, 0, 0.70)",
            textAlign: "right",
            fontSize: "22px",
            fontWeight: "700",
          }}
        >
          {tn("Add a new user")}
        </Text>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 10px",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "48%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <TextInput
              withAsterisk
              label={tn("First Name")}
              {...form.getInputProps("first_name")}
              styles={() => textInputStyle}
              size="xs"
              style={{ width: "100%" }}
            />
            <TextInput
              withAsterisk
              label={tn("Email")}
              {...form.getInputProps("email")}
              styles={() => textInputStyle}
              size="xs"
              style={{ width: "100%" }}
            />
            {/* <Select
            withAsterisk
              data={[]}
              label={tn("Department")}
              {...form.getInputProps("department")}
              styles={() => textInputStyle}
              size="xs"
              style={{ width: "100%" }}
            /> */}
            <Select
              data={roles
                ?.filter((role: any) => role?.name != "hr_manager")
                ?.map((role: any) => ({
                  value: role?.name,
                  label: role?.[locale],
                }))}
              withAsterisk
              label={tn("Premmissions")}
              {...form.getInputProps("premmissions")}
              styles={() => textInputStyle}
              size="xs"
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              width: "48%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <TextInput
              withAsterisk
              label={tn("Last Name")}
              {...form.getInputProps("last_name")}
              styles={() => textInputStyle}
              size="xs"
              style={{ width: "100%" }}
            />
            <TextInput
              withAsterisk
              error={form.errors.mobile}
              label={tn("Mobile")}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              type="number"
              styles={() => textInputStyle}
              size="xs"
              style={{ width: "100%" }}
              rightSectionWidth={60}
              rightSection={
                <NativeSelect
                  onChange={(e) => setCountryNum(e.target.value)}
                  value={countryNum}
                  data={countries_numbers}
                  styles={(theme) => ({
                    label: {
                      color: "#ffffff",
                    },
                    input: {
                      border: "0px solid #69DCBF",
                      borderRadius: "0px",
                      color: "white",
                      backgroundColor: "transparent",
                      // borderBottom: "1px solid #69dcbf86",
                      "&:focus-within": {
                        // borderBottom: "1px solid #69DCBF",
                      },
                    },
                  })}
                />
              }
            />
            <TextInput
              withAsterisk
              label={tn("Transfer No")}
              {...form.getInputProps("transfer_no")}
              styles={() => textInputStyle}
              size="xs"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            padding: "20px 0px",
          }}
        >
          <Button
            type="submit"
            mx="sm"
            style={{
              backgroundColor: "#69DCBF",
              color: "white",
              width: "100px",
              borderRadius: "5.112px",
            }}
          >
            {tn("Save")}
          </Button>
          <Button
            mx="sm"
            style={{
              color: "white",
              width: "100px",
              borderRadius: "5.112px",
              backgroundColor: "#F9837C",
            }}
            color="red"
            onClick={() => {
              onClose({});
            }}
          >
            {tn("Cancel")}
          </Button>
        </div>
      </div>
    </form>
  );
};
const textInputStyle = {
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 10px)",
    background: "white",
    padding: "0px 5px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
};
