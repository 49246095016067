import {
  TextInput,
  PasswordInput,
  Button,
  Text,
  Anchor,
  Avatar,
  FileButton,
  Tabs,
  Divider,
  Select,
  NumberInput,
  SegmentedControl,
  Textarea,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { isNotEmpty, useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../context/TranslationContext";
import { tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT, countries_numbers } from "../../../constants";
import { Helmet } from "react-helmet";
import { containsArabicNumbers, convertArabicNumbersToEnglish, convertEnglishNumbersToArabic, vf } from "../../../main_components/Functions";

function FillPersonalProfile() {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const [avatar, setAvatar] = useState("");
  const [isValidatedError, setIsValidatedError] = useState(false);
  const navigate = useNavigate();
  const [isError, setisError]: any = useState(false);
  const [buttonLoading, setButtonLoading]: any = useState(false);
  const [phoneNumber, setPhoneNumber]: any = useState("");
  const [countryNum, setCountryNum] = useState("+966");
  const { locale, translation }: any = useContext(TranslationContext);

  const [maxGPA, setMaxGPA] = useState(5);
  const universities = vf("university"); 
  const majors = vf("major"); 
  const semesters = vf("semester");
  const degrees = vf("degree");
  const graduation_years = vf("graduation_year");
  const GPAerror = vtn("GPA");
  const MobileError = vtn("mobile");
  const form: any = useForm({
    initialValues: {
      avatar: "",
      gender: "male",
      phone: "",
      university: "",
      degree: "",
      major: "",
      GPA: 0,
      graduation_year: "2024",
      semester: "",
      CV: "",
      about: "",
      country_mobile_number: "",
    },

    validate: {
      phone: (value) => ((!isNaN(phoneNumber) && phoneNumber.length == 9 )? null : MobileError),
      GPA: (value) => (value > 0 && value <= maxGPA ? null : GPAerror),
      CV: isNotEmpty("CV"),
    },
  });
  console.log(form);

  const [GPAfirst, setGPAfirst] = useState("")
  useEffect(() => {
    form.setFieldValue("GPA", null);
    setGPAfirst("");
  }, [maxGPA]);
  useEffect(() => {
      const formatted = convertArabicNumbersToEnglish(GPAfirst);
      const value:any = !isNaN(formatted)? parseFloat(formatted):null;
      if(
        value > 0
      ){
        if(value > maxGPA){
          form.setFieldValue("GPA", maxGPA);
          setGPAfirst(
            !containsArabicNumbers(GPAfirst) ?
            maxGPA.toString():
            convertEnglishNumbersToArabic(maxGPA.toString())
          );
        }else{
          form.setFieldValue("GPA", value);
        }
      }else{
        form.setFieldValue("GPA", null);
      }
  }, [GPAfirst]);

  useEffect(() => {
    form.setFieldValue("phone", countryNum + phoneNumber);
  }, [countryNum]);
  useEffect(() => {
    form.setFieldValue("phone", countryNum + phoneNumber);
  }, [phoneNumber]);


  return (
    <>
      <Helmet>
        <title>{tn("Login") + " / " + tn("Coophub")}</title>
      </Helmet>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          height: "100vh",
        }}
        onSubmit={form.onSubmit((value: any) => { 
          const data = {
            mobile: value.phone,
            gender:
              value.gender
               ,
            university: value.university,
            major: value.major,
            GPA: value.GPA,
            full_info: true,
            GPA_out_of: maxGPA,
            about: value.about,
            country_mobile_number:countryNum,
            graduation_year:value.graduation_year,
            semester:value.semester,
            degree:value.degree,
          }; 
          console.log("datadatadatadddatadata",data);
          try {
          
          const formData = new FormData();
          formData.append("avatar", value?.avatar);
          formData.append("cv", value?.CV);
         
            const record = pb
            .collection("applicants")
            .update(pb?.authStore?.model?.id, data)?.then(()=>{
              const record_ = pb
              .collection("applicants")
              .update(pb?.authStore?.model?.id, formData);
              navigate("/applicant/programs_search");
            });
          } catch (error) {
          }
        
      })}
      >
        <Text
          style={{
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            color: "#69DCBF",
            marginTop: "40px",
          }}
        >
          {tn("Profile")}
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            padding: "20px",
            margin: "40px 20px 20px",
            gap: "20px",
            width: "calc(100% - 40px)",
            borderRadius: "15px",
            boxShadow: "1px 3px 30px 0px rgba(0, 0, 0, 0.13)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <FileButton
              maxSize={1024 ** 2}
              {...form.getInputProps("avatar")}
              onChange={async (file: any) => {
                form.setFieldValue("avatar", file);
                setAvatar(URL.createObjectURL(file));
              }}
              accept="image/png,image/jpeg"
            >
              {(props: any) => (
                <Button
                  {...props}
                  color="color3"
                  radius="10.112px"
                  style={{
                    width: "100px",
                  }}
                >
                  {tn("Upload")}
                </Button>
              )}
            </FileButton>
            <Avatar src={avatar} radius="100%" size={100}>
              {" "}
              <img
                style={{
                  width: "125%",
                  height: "125%",
                  opacity: "0.5",
                }}
                src={"/avatar_placeholder.svg"}
              />
            </Avatar>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <Text color="#21033F" fw="600">{tn("Gender")}</Text>
            <Tabs
              styles={() => ({
                tabLabel: {
                  color: "#69DCBF",
                },
              })}
              color="color2"
              value={form.values?.gender}
              onTabChange={(value) => {
                form.setFieldValue("gender", value);
                console.log(value);
              }}
            >
              <Tabs.List>
                <Tabs.Tab value="male"> {tn("Male")}</Tabs.Tab>
                <Tabs.Tab value="female">{tn("Female")}</Tabs.Tab>
              </Tabs.List>
            </Tabs>
          </div>
          <Divider
            style={{
              width: "100%",
              margin: "10px 0px",
            }}
          />
          <TextInput
            error={form.errors.phone}
            onChange={(e) => setPhoneNumber(convertArabicNumbersToEnglish(e.target.value))}
            type="text" // Changed to text to handle both Arabic and English characters
            label={tn("Mobile")}
            rightSection={
              <Select
                size="xs"
                onChange={(e) => setCountryNum(e)}
                value={countryNum}
                data={countries_numbers}
                styles={(theme) => ({
                  input: {
                    fontSize: "13px",
                    border: "0px solid #69DCBF",
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "transparent",
                  },
                })}
              />
            }
            rightSectionWidth={60}
           
            {...InputStyle}

          />
            <Select
            {...form.getInputProps("university")}
            searchable
            data={
              universities?.[0]
                ? universities?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
           
            label={tn("University")}
            {...InputStyle}
          />
          <Select
            {...form.getInputProps("degree")}
            searchable
            data={
              degrees?.[0]
                ? degrees?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
            label={tn("Degree")}
            {...InputStyle}
          />
          <Select
            {...form.getInputProps("major")}
            searchable
            data={
              majors?.[0]
                ? majors?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
            label={tn("Major")}
            {...InputStyle}
          />
         <TextInput
  onBlur={(e) => {
      if (!form?.values?.GPA) {
        setGPAfirst("");
      }
  }}
  onChange={(e) => {
    setGPAfirst(e.target.value);
  }}
  value={
    GPAfirst
  }
            label={tn("GPA")}
            {...InputStyle}
            rightSection={
              <SegmentedControl
              onChange={(value) => {
                setMaxGPA(parseInt(value));
              }}
              style={{ width: "70px" }}
              styles={() => ({
                root: {
                  color: "#6f6f6f",
                  backgroundColor: "transparent",
                },
                label: {
                  color: "#6f6f6f",
                  height: "25px",
                  borderRadius: "2px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 10px",
                  "&:hover": {
                    color: "#fff",
                  },
                  '&[data-active="true"]': {
                    color: "#fff",
                  },
                  "&[data-active]:hover": {
                    color: "#fff",
                  },
                },
                indicator: {
                  color: "#6f6f6f",
                  backgroundColor: "rgba(33, 3, 63, 0.70) !important",
                  borderRadius: "2px",
                },
                control: {
                  // width: "60px",
                  color: "#6f6f6f",
                  // backgroundColor: "#91919160 !important",
                  borderRadius: "2px",
                },
                controlActive: { color: "#6f6f6f" },
              })}
              data={[
                { label: locale == "en" ? "5" : "٥", value: "5" },
                { label: locale == "en" ? "4" : "٤", value: "4" },
              ]}
            />}
            rightSectionWidth={70}
          />
          <Select
            {...form.getInputProps("graduation_year")}
            data={
              graduation_years?.[0]
                ? graduation_years?.map((option: any) => ({
                  value: option?.name,
                    label: option?.[locale],
                  })
                )
                : []
            }
            label={tn("Graduation Year")}
            {...InputStyle}
          />
          <Select
            {...form.getInputProps("semester")}
            searchable
            data={
              semesters?.[0]
                ? semesters?.map((option: any) => ({
                  value: option?.name,
                    label: option?.[locale],
                  })
                )
                : []
            }
            label={tn("Semester")}
            {...InputStyle}
          />
           <Divider  
          style={{
            width: "100%",
            margin: "10px 0px",
          }}
        />
         <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
               <Text
            style={{
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              color:"#21033F"
            }}
          >
            {form?.values?.CV ? form?.values?.CV?.name :  tn("CV")}
          </Text>
           <FileButton
            maxSize={1024 ** 2}
            {...form.getInputProps("CV")}
        
            accept=".pdf"
          >
            {(props: any) => (
              <Button
                {...props}
                color="color3"
                radius="10.112px"
                style={{
                  width: "100px",
                }}
              >
                {tn("Upload")}
              </Button>
            )}
          </FileButton>
       
          </div>
          {form.errors.CV && (
            <Text
              style={{
                color: "red",
                fontSize: "12px",
                textAlign: "center",
                width: "100%",
              }}
            >
              {form.errors.CV}
            </Text>
          )}
         
          <Textarea
            {...form.getInputProps("about")}
            label={tn("About")}
            {...InputStyle}
            required={false}
          />

        </div>
       

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            width: "100%",
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            loading={buttonLoading}
            color="color2"
            style={{
              width: "120px",
              height: "60px",
              borderRadius: "15px",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
            type="submit"
          >
            {tn("Continue")}
          </Button>
        </div>
      </form>
    </>
  );
}

export default FillPersonalProfile;

const divider = (
  <div
    style={{
      width: "1px",
      height: "15px",
      background: "#767676",
      margin: "0px 5px",
    }}
  />
);

const InputStyle = {
  withAsterisk:false,
  required: true,
  style: {
    width: "100%",
  },
  size: "md",
  styles: () => ({
    label: {
      color:"#21033F",
      transform: "translate(10px, -3.5px)",
    },
    input: {
      borderRadius: "10px",
      border: "1px solid #000000",
    },
    error: {
      marginBottom: "-13px",
      fontSize: "12px",
    },
  }),
};

const IconStyle = {
  size: "1.5rem",
  stroke: 1.5,
  style: {
    color: "#a1a1a1",
  },
};
