import React, { useState } from 'react';

async function AItool({prompt}) {
    const apiKey = 'sk-proj-crSE2zIFTlvhDU8VWH2VT3BlbkFJaacgp5iDi7YH6FnVFCgx'; // Replace with your actual OpenAI API key
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: prompt }],
      }),
    };

    try {
      const res = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
      const data = await res.json();
      return data.choices[0].message.content
    } catch (error) {
      console.error('Error fetching OpenAI response:', error);
      return 'Error fetching OpenAI response';
    }

 
}

export default AItool;
