import React from "react";
import { useForm } from "@mantine/form";
import {
  TextInput,
  NumberInput,
  Select,
  Group,
  Button,
  Box,
  Textarea,
  Checkbox,
  Radio,
  MultiSelect,
  Switch,
  PasswordInput,
  FileInput,
  Slider,
  ColorInput,
} from "@mantine/core";

import { DatePicker } from "@mantine/dates";
import { main_input_style } from "./components_styles";
function LargeForm() {
  const form = useForm({
    initialValues: {
      personalInfo: {
        firstName: "",
        lastName: "",
        age: 0,
        gender: "",
        dateOfBirth: null,
        hobbies: [],
        email: "",
        phoneNumber: "",
        password: "",
      },
      address: {
        street: "",
        city: "",
        country: "",
        postalCode: "",
      },
      experiences: [
        {
          company: "",
          role: "",
          years: 0,
          responsibilities: "",
          achievements: "",
        },
      ],
      skills: [],
      certifications: [{ name: "", issuedBy: "", year: 0 }],
      consent: false,
      newsletter: false,
      profilePicture: null,
    },
  });

  const addExperience = () => {
    form.insertListItem("experiences", {
      company: "",
      role: "",
      years: 0,
      responsibilities: "",
      achievements: "",
    });
  };

  const addCertification = () => {
    form.insertListItem("certifications", { name: "", issuedBy: "", year: 0 });
  };

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <Box maw={800} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          label="First Name"
          placeholder="Enter your first name"
          {...form.getInputProps("personalInfo.firstName")}
        />
          <ColorInput 
                    {...form.getInputProps("cv_color")}
                    className=" w-full"
                    styles={main_input_style(false)}
                 format="hex"
                 swatches={['#2e2e2e', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
               />
               
        <TextInput
          label="Last Name"
          placeholder="Enter your last name"
          {...form.getInputProps("personalInfo.lastName")}
        />
        <NumberInput
          label="Age"
          placeholder="Enter your age"
          {...form.getInputProps("personalInfo.age")}
        />
       
        <DatePicker
          label="Date of Birth"
          placeholder="Select your date of birth"
          {...form.getInputProps("personalInfo.dateOfBirth")}
        />
        <MultiSelect
          label="Hobbies"
          placeholder="Select your hobbies"
          data={["Reading", "Traveling", "Cooking", "Sports", "Music"]}
          {...form.getInputProps("personalInfo.hobbies")}
        />
        <TextInput
          label="Email"
          placeholder="Enter your email"
          {...form.getInputProps("personalInfo.email")}
        />
        <TextInput
          label="Phone Number"
          placeholder="Enter your phone number"
          {...form.getInputProps("personalInfo.phoneNumber")}
        />
        <PasswordInput
          label="Password"
          placeholder="Enter your password"
          {...form.getInputProps("personalInfo.password")}
        />

        <TextInput
          label="Street Address"
          placeholder="Enter your street address"
          {...form.getInputProps("address.street")}
        />
        <TextInput
          label="City"
          placeholder="Enter your city"
          {...form.getInputProps("address.city")}
        />
        <TextInput
          label="Postal Code"
          placeholder="Enter your postal code"
          {...form.getInputProps("address.postalCode")}
        />
        <Select
          label="Country"
          placeholder="Select your country"
          data={[
            { value: "us", label: "United States" },
            { value: "ca", label: "Canada" },
            { value: "other", label: "Other" },
          ]}
          {...form.getInputProps("address.country")}
        />

        {form.values.experiences.map((_, index) => (
          <Group key={index} mt="md">
            <TextInput
              label="Company"
              placeholder="Enter company name"
              {...form.getInputProps(`experiences.${index}.company`)}
            />
            <TextInput
              label="Role"
              placeholder="Enter your role"
              {...form.getInputProps(`experiences.${index}.role`)}
            />
            <NumberInput
              label="Years"
              placeholder="Enter years of experience"
              {...form.getInputProps(`experiences.${index}.years`)}
            />
            <Textarea
              label="Responsibilities"
              placeholder="Describe your responsibilities"
              {...form.getInputProps(`experiences.${index}.responsibilities`)}
            />
            <Textarea
              label="Achievements"
              placeholder="Describe your achievements"
              {...form.getInputProps(`experiences.${index}.achievements`)}
            />
          </Group>
        ))}

        <Group mt="md">
          <Button variant="light" onClick={addExperience}>
            Add Experience
          </Button>
        </Group>

        {form.values.certifications.map((_, index) => (
          <Group key={index} mt="md">
            <TextInput
              label="Certification Name"
              placeholder="Enter certification name"
              {...form.getInputProps(`certifications.${index}.name`)}
            />
            <TextInput
              label="Issued By"
              placeholder="Enter issuing organization"
              {...form.getInputProps(`certifications.${index}.issuedBy`)}
            />
            <NumberInput
              label="Year"
              placeholder="Enter year of certification"
              {...form.getInputProps(`certifications.${index}.year`)}
            />
          </Group>
        ))}

        <Group mt="md">
          <Button variant="light" onClick={addCertification}>
            Add Certification
          </Button>
        </Group>

        <MultiSelect
          label="Skills"
          placeholder="Select your skills"
          data={["JavaScript", "React", "Node.js", "CSS", "HTML", "SQL"]}
          {...form.getInputProps("skills")}
        />

        <FileInput
          label="Profile Picture"
          placeholder="Upload your profile picture"
          {...form.getInputProps("profilePicture")}
        />

        <Textarea
          label="Additional Notes"
          placeholder="Enter any additional notes"
          mt="md"
          {...form.getInputProps("additionalNotes")}
        />

        <Checkbox
          label="I agree to the terms and conditions"
          mt="md"
          {...form.getInputProps("consent", { type: "checkbox" })}
        />

        <Switch
          label="Subscribe to newsletter"
          mt="md"
          {...form.getInputProps("newsletter", { type: "checkbox" })}
        />

        <Slider
          label="Satisfaction Level"
          mt="md"
          min={0}
          max={10}
          {...form.getInputProps("satisfactionLevel")}
        />

        <Group mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
}

export default LargeForm;