import {
  IconCircleCheck,
  IconEdit,
  IconPhoto,
  IconSquareRoundedCheck,
  IconSquareRoundedX,
  IconTrash,
  IconUpload,
} from "@tabler/icons-react";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT, countries_numbers } from "../../../../../../constants";
import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Center,
  Dialog,
  FileInput,
  Group,
  Loader,
  Modal,
  NativeSelect,
  NumberInput,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { ltn, tn, vtn } from "../../../../../../translation";
import {
  useDebouncedState,
  useDebouncedValue,
  useDisclosure,
  useTimeout,
  useViewportSize,
} from "@mantine/hooks";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { motion } from "framer-motion";
import { useForm, isEmail } from "@mantine/form";
import { MIME_TYPES } from "@mantine/dropzone";
import { AppContext } from "../../../../../../context/AppContext";
import { BannersContext } from "../../../../../../context/BannersContext";
import { convertArabicNumbersToEnglish } from "../../../../../Functions";

export function EditRecruiter({ onClose, data, onSubmit }: any) {
  const [debounced_data] = useDebouncedValue(data?.record, 200);

  const pb: any = new PocketBase(CMS_ENDPOINT);

  const { locale, translation }: any = useContext(TranslationContext);
  const { reloader, setReloader }: any = useContext(AppContext);

  const [discardModal, setDiscardModal] = useState(false);
  const { height, width } = useViewportSize();
  const validate_text = [
    vtn("case_study"),
    vtn("first_name"),
    vtn("last_name"),
    vtn("taken_email"),
    vtn("email"),
    vtn("password"),
    vtn("password_comfirm"),
    vtn("arabic_name2"),
    vtn("mobile"),
    vtn("university"),
    vtn("major"),
    vtn("GPA"),
    vtn("graduation_year"),
    vtn("semester"),
  ];

  const form: any = useForm({
    initialValues: {
      first_name: debounced_data?.name?.split(" ")?.[0],
      last_name: debounced_data?.name?.split(" ")?.[1],
      email: debounced_data?.email,
      mobile: debounced_data?.mobile,
      permission: debounced_data?.permission,
      country_mobile_number: debounced_data?.country_mobile_number,
      extension: debounced_data?.extension,
      department: debounced_data?.department,
    },
    validate: {
      email: isEmail(" "),
      first_name: (value) =>
        !/^\S+ \S+$/.test(value) && value.length > 2 && !/[0-9]/.test(value)
          ? /^[\u0621-\u064A\s]+$/.test(value)
            ? null
            : validate_text?.[7]
          : validate_text?.[1],
      last_name: (value) =>
        /^[\u0621-\u064A\s]+$/.test(value) ? null : validate_text?.[7],
      mobile: (value, values) =>
        value.length == values?.country_mobile_number.length + 9
          ? null
          : validate_text?.[8],
          permission: (value) => (!pb.authStore.model?.expand?.company?.is_partial ?value.length > 0 ? null : " ":null),
    },
  });
  //   useEffect(() => {
  //     form.setValues({
  //       first_name: data?.name?.split(" ")?.[0],
  //       last_name: data?.name?.split(" ")[1],
  //       gender: data?.gender,
  //       mobile: data?.mobile,
  //       case_study: data?.case_study,
  //       university: data?.university,
  //       major: data?.major,
  //       GPA: parseInt(data?.GPA),
  //       CV: data?.cv,
  //     });
  //   }, [data]);
  const [loading, setLoading] = useDebouncedState(false, 800);
  const [phoneNumber, setPhoneNumber]: any = useState("");
  const [departments, setDepartments] = useState([]);
  const { setTopBanner }: any = useContext(BannersContext);
  const [permissions, setPermissions] = useState([])
  const dialogMassage = tn("Data has been updated");

  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    if (phoneNumber.length > 0) {
      form.setFieldValue("mobile", "");
    }
  }, [form.values.country_mobile_number]);
  useEffect(() => {
    if (phoneNumber.length > 0) {
      form.setFieldValue(
        "mobile",
        form.values.country_mobile_number + phoneNumber
      );
    } else {
      form.setFieldValue("mobile", debounced_data?.mobile);
    }
  }, [phoneNumber]);
  useEffect(() => {
    const resultList = pb
      .collection("departments_view")
      .getFullList({})
      .then((resultList: any) => {
        setDepartments(resultList);
      });
  }, []);
  useEffect(() => {
    const permissions = pb
      .collection("permissions")
      .getList(1, 100, {
        filter: ``,
        sort: "created",
      })
      .then((res: any) => {
        setPermissions( res.items);
      });
  }, []);
  useEffect(() => {
    form.setFieldValue("extension", convertArabicNumbersToEnglish(form.values.extension));
  }, [
    form.values.extension
  ])
  
  return (
    <>
      {!loading ? (
        <div
          style={{
            width: "100%",
          }}
        >
          <form
            onSubmit={form.onSubmit((values: any) => {
              const data_ = {
                name: values?.first_name + " " + values?.last_name,
                mobile: values?.mobile,
                email: values?.email,
                country_mobile_number: values?.country_mobile_number,
                permission: values.permission,
                extension: values?.extension,
                department: values?.department,
              };

              const record = pb
                .collection("recruiters")
                .update(debounced_data?.id, data_)
                .then((result: any) => {
                  setTopBanner({
                    message: dialogMassage,
                    color: "#2DC071",
                    top: "83px",
                    backgroundColor: "#F2FFF7EB",
                    icon: <IconSquareRoundedCheck />,
                  });
                  onClose({});
                  setReloader(!reloader);
                });
            })}
          >
            <div
              style={{
                background: "white",
                width: "100%",
                height: "380px",
                marginTop: "9px",
                borderRadius: "5px",
                paddingTop: "20px",
                paddingBottom: "40px",
                display: "flex",
                // flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "calc(27%)",
                  height: "100%",
                  display: "flex",
                  margin: "10px",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                  background: "rgba(79, 81, 82, 0.05)",
                }}
              >
                <Avatar
                  variant="outline"
                  radius="100%"
                  size={100}
                  styles={() => ({
                    placeholderIcon: {
                      width: "120%",
                      height: "120%",
                      color: "#4F5152",
                      minWidth: "120%",
                      minHeight: "120%",
                    },
                  })}
                  src={`${CMS_ENDPOINT}/api/files/${debounced_data?.collectionName}/${debounced_data?.id}/${debounced_data?.avatar}?token=`}
                >
                  <img
                    style={{
                      width: "125%",
                      height: "125%",
                    }}
                    src={"/avatar_placeholder.svg"}
                  />
                </Avatar>
                <Text mt="md" fw={700} color="#4D4D4D" fz={16}>
                  {debounced_data?.name?.split(" ")?.[0] +
                    " " +
                    debounced_data?.name?.split(" ")[1]}
                </Text>
                <Text mt={5} fw={700} color="#4D4D4D" fz={12}>
                  {debounced_data?.role?.[locale]}
                </Text>
                <Text mt={5} fw={700} color="#4D4D4D" fz={12}>
                  {debounced_data?.email}
                </Text>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {/* <Button
                    type="submit"
                    // radius was 0 here
                    onClick={() => {}}
                    style={{
                      top: "0px",
                      left: "0px",
                      position: "absolute",
                      background: "#69DCBF",
                      height: "25px",
                    //   width: "100px",
                      margin: "0px 20px",
                    }}
                  >
                    {tn("Edit data")}
                  </Button> */}
                </div>
              </div>
              <div
                style={{
                  maxWidth: "calc(100% - 260px)",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 20px",
                  margin: "10px",
                  justifyContent: "space-between",
                  flexDirection: width < 850 ? "column" : "row",
                  borderRadius: "10px",
                  background: "rgba(79, 81, 82, 0.05)",
                }}
              >
                <div
                  style={{
                    width:
                      width < 850 ? "calc(100% - 10px)" : "calc(50% - 10px)",
                  }}
                >
                  <Text mb="xl" color="#676767" size="lg" fw="700">
                    {tn("Personal information")}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Text color="#676767" size={12} fw="700">
                      {tn("First Name")}
                    </Text>
                    <TextInput
                      //variant="filled"
                      required
                      {...form.getInputProps("first_name")}
                      style={{
                        width: "60%",
                        border: "1px solid #000",
                        borderRadius: "5px",
                      }}
                      styles={(theme) => ({
                        error: {
                          marginBottom: "-17px",
                          transform: "translateY(-4px)",
                        },
                      })}
                      size="xs"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "13px",
                    }}
                  >
                    <Text color="#676767" size={12} fw="700">
                      {tn("Mobile")}
                    </Text>
                    <TextInput
                      styles={(theme) => ({
                        error: {
                          marginBottom: "-17px",
                          transform: "translateY(-4px)",
                        },
                      })}
                      // required
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={form.values.mobile?.replace(
                        form.values.country_mobile_number,
                        ""
                      )}
                      type="number"
                      error={form.errors.mobile}
                      // label={tn("Mobile")}

                      rightSection={
                        <NativeSelect
                          {...form.getInputProps("country_mobile_number")}
                          // value={countryNum}
                          data={countries_numbers}
                          styles={(theme) => ({
                            label: {
                              color: "#ffffff",
                            },
                            input: {
                              fontSize: "13px",
                              border: "0px solid #69DCBF",
                              borderRadius: "0px",
                              color: "white",
                              backgroundColor: "transparent",
                              // borderBottom: "1px solid #69dcbf86",
                              "&:focus-within": {
                                // borderBottom: "1px solid #69DCBF",
                              },
                            },
                          })}
                        />
                      }
                      rightSectionWidth={60}
                      withAsterisk={false}
                      size="xs"
                      style={{
                        direction: "ltr",
                        textAlign: "right",
                        width: "60%",
                        border: "1px solid #000",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  {!pb.authStore.model?.expand?.company?.is_partial ?<div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "13px",
                    }}
                  >
                    <Text color="#676767" size={12} fw="700">
                      {tn("Department")}
                    </Text>
                    <Select
                      //variant="filled"
                      searchable
                      clearable
                      // required
                      data={
                        departments?.[0]
                          ? departments?.map((department: any) => {
                            const isDisabled = department?.recruiter != debounced_data?.id && department?.recruiter;
                              return {
                                value: department?.id,
                                label: department?.name,
                                disabled:
                                isDisabled,
                              };
                            })
                          : []
                      }
                      {...form.getInputProps("department")}
                      style={{
                        width: "60%",
                        border: "1px solid #000",
                        borderRadius: "5px",
                      }}
                      size="xs"
                    />
                    {/* <div
                      style={{
                        height: "30px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        boxSizing: "border-box",
                        fontSize: "0.875rem",
                        width: "60%",
                        color: "#727272",
                        backgroundColor: "#f1f3f5",
                        paddingRight: "calc(2.25rem / 3)",
                        paddingLeft: "calc(2.25rem / 3)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        border: "1px solid #000",
                        borderRadius: "5px",
                      }}
                    >
                      {debounced_data?.department?.[locale]}
                    </div> */}
                  </div>:<div style={{
                    height: "90px",
                  }}/>}
                 {!pb.authStore.model?.expand?.company?.is_partial && <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "13px",
                    }}
                  >
                    <Text color="#676767" size={12} fw="700">
                      {tn("Premmissions")}
                    </Text>
                    <Select
                      //variant="filled"
                      searchable
                      required
                      data={permissions?.map((permission: any) => ({
                        value: permission?.id,
                        label: permission?.name,
                      }))}
                      {...form.getInputProps("permission")}
                      style={{
                        width: "60%",
                        border: "1px solid #000",
                        borderRadius: "5px",
                      }}
                      size="xs"
                    />
                  </div>}
                </div>
                <div
                  style={{
                    width:
                      width < 850 ? "calc(100% - 10px)" : "calc(50% - 10px)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    marginTop: "-0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      //   marginTop: "48px",
                    }}
                  >
                    <Text color="#676767" size={12} fw="700">
                      {tn("Last Name")}
                    </Text>
                    <TextInput
                      //variant="filled"
                      required
                      {...form.getInputProps("last_name")}
                      style={{
                        width: "60%",
                        border: "1px solid #000",
                        borderRadius: "5px",
                      }}
                      styles={(theme) => ({
                        error: {
                          marginBottom: "-17px",
                          transform: "translateY(-4px)",
                        },
                      })}
                      size={"xs"}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "13px",
                    }}
                  >
                    <Text color="#676767" size={12} fw="700">
                      {tn("Email")}
                    </Text>
                    <div
                      style={{
                        height: "30px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        boxSizing: "border-box",
                        fontSize:
                          data?.record?.email?.length > 16 ? "10px" : "0.575rem",
                        width: "60%",
                        color: "#727272",
                        backgroundColor: "#f1f3f5",
                        paddingRight: "calc(2.25rem / 3)",
                        paddingLeft: "calc(2.25rem / 3)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        border: "1px solid #000",
                        borderRadius: "5px",
                      }}
                    >
                      {debounced_data?.email}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "13px",
                    }}
                  >
                    <Text color="#676767" size={12} fw="700">
                      {tn("Extension")}
                    </Text>
                    <TextInput
                      //variant="filled"
                      {...form.getInputProps("extension")}
                      style={{
                        width: "60%",
                        border: "1px solid #000",
                        borderRadius: "5px",
                      }}
                      hide
                      styles={(theme) => ({
                        // error: {
                        //   marginBottom: "-17px",
                        //   transform: "translateY(-4px)",
                        // },
                      })}
                      size={"xs"}
                    />
                  </div>
                </div>
              </div>
              <Button
                disabled={!form.isDirty()}
                type="submit"
                // radius was 0 here
                onClick={() => {}}
                style={{
                  bottom: "0px",
                  left: "120px",
                  position: "absolute",
                  background: form.isDirty() ? "#69DCBF" : "",
                  height: "25px",
                  width: "100px",
                  margin: "8px 20px",
                }}
              >
                {tn("Save")}
              </Button>
              <Button
                // radius was 0 here
                onClick={() =>
                  form.isDirty() ? setDiscardModal(true) : onClose({})
                }
                style={{
                  background: "#F9837C",
                  bottom: "0px",
                  left: "0px",
                  position: "absolute",
                  height: "25px",
                  width: "100px",
                  margin: "8px 20px",
                }}
              >
                {tn("close")}
              </Button>
            </div>
          </form>
          <Modal
            style={{ zIndex: 10000 }}
            overlayProps={{
              zIndex: 10000000,
              opacity: 0.55,
              blur: 3,
            }}
            styles={() => ({
              content: {
                zIndex: 22222001,
                borderRadius: "10px",
                background:
                  "linear-gradient(153deg, #22c0c372 100%, #21033f7a 100%)",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
              },
              header: { display: "none" },
              body: { padding: "9px" },
              inner: { zIndex: 20222202 },
            })}
            opened={discardModal}
            onClose={() => {
              setDiscardModal(false);
            }}
            centered
          >
            <div
              style={{
                background: "white",
                width: "100%",
                height: "100%",
                marginTop: "9px",
                borderRadius: "5px",
                paddingTop: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Text fw="600" color="grey">
                  {ltn("discard_massage")}
                </Text>
              </div>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Button
                  my="md"
                  style={{ backgroundColor: "#F9837C", width: "100px" }}
                  onClick={() => {
                    setDiscardModal(false);
                    onClose({});
                  }}
                >
                  {tn("Yes")}
                </Button>
                <Button
                  my="md"
                  mx="md"
                  variant="outline"
                  color="gray"
                  onClick={() => {
                    setDiscardModal(false);
                  }}
                >
                  {tn("Cancel")}
                </Button>{" "}
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <div
          style={{
            background: "white",
            height: "512.9px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "9px",
            borderRadius: "5px",
          }}
        >
          <Loader />
        </div>
      )}
    </>
  );
}
