import { useViewportSize } from "@mantine/hooks";
import { Text, Avatar, Switch, Button, FileButton } from "@mantine/core";
import { CMS_ENDPOINT } from "../../../../constants";
import PocketBase from "pocketbase";
import { ReactNode, useContext, useEffect, useState } from "react";
import { tn } from "../../../../translation";
import { TranslationContext } from "../../../../context/TranslationContext";
import { motion } from "framer-motion";
import { IconPencil, IconSquareRoundedCheck, IconX } from "@tabler/icons-react";
import { MIME_TYPES } from "@mantine/dropzone";
import { BannersContext } from "../../../../context/BannersContext";
import { AppContext } from "../../../../context/AppContext";
import { fv } from "../../../../main_components/Functions";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function MainDataProfile() {
  const { height, width }: any = useViewportSize();
  const { locale, translation }: any = useContext(TranslationContext);
  const { reloader, setReloader }: any = useContext(AppContext);

  const { setTopBanner }: any = useContext(BannersContext);
  const [userData, setUserData] = useState(pb?.authStore?.model);

  const [discoverable, setDiscoverable] = useState(userData?.discoverable);
  const [onHover, setOnHover]: any = useState(false);

  const [avatar, setAvatar] = useState(
    userData?.avatar &&
      `${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.avatar}?token=`
  );

  const CVdeleted = tn("CV deleted");
  const CVuploaded = tn("CV uploaded");
  useEffect(() => {
    setUserData(pb?.authStore?.model);
  }, [reloader]);
  return (
    <div
      style={{
        padding: "30px",
        borderRadius: "14px",
        width: "100%",
        maxWidth: "1180px",
        border: "1px solid rgba(7, 1, 13, 0.15)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap:"40px",
        position: 'relative',
        // backgroundImage: "url('/full_waves.png')",
        // backgroundSize: "cover",
      }}
    >
        <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          backgroundImage: "url('/full_waves.png')",
          backgroundSize: "cover",
          // backgroundPosition: 'center',
          opacity: 0.2,
        }}
      ></div>
        <div
        style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",position: 'relative', zIndex: 1,
        gap:"40px",
        }}
      >
      <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap:"40px"
      }}
      >
      <FileButton
        maxSize={1024 ** 2}
        onChange={async (file: any) => {
          const formData = new FormData();
          formData.append("avatar", file);
          const record = pb
            .collection("applicants")
            .update(userData?.id, formData);
          setAvatar(URL.createObjectURL(file));
          setReloader(!reloader);
        }}
        accept="image/png,image/jpeg"
      >
        {(props) => (
          <motion.div
            {...props}
            style={{
              cursor: "pointer",
              padding: "1px",
              position: "relative",
            }}
            onHoverStart={() => {
              setOnHover(true);
            }}
            onHoverEnd={() => {
              setOnHover(false);
            }}
          >
            <Avatar
              styles={() => ({
                placeholderIcon: {
                  width: "120%",
                  height: "120%",
                  color: "#4F5152",
                  minWidth: "120%",
                  minHeight: "120%",
                },
              })}
              src={avatar}
              size={120}
              radius="100%"
              variant="outline"
            >
              <img
                style={{
                  width: "125%",
                  height: "125%",
                }}
                src={"/avatar_placeholder.svg"}
              />
            </Avatar>
            <motion.div
              animate={{
                width: onHover ? "130px" : "36px",
                height: onHover ? "130px" : "36px",
              }}
              style={{
                width: "23px",
                height: "23px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#21033fac",
                border: "1px solid #ffffff9b",
                borderRadius: "1000px",
                position: "absolute",
                bottom: "0px",
                right: "0px",
                padding: "2px",
                transform: "translate(4px,4px)",
                flexDirection: "column",
              }}
            >
              {avatar && (
                <motion.div
                  onClick={async (e) => {
                    e.stopPropagation();
                    setAvatar("");
                    const record = pb
                      .collection("applicants")
                      .update(userData?.id, {
                        avatar: null,
                      });
                    setReloader(!reloader);
                  }}
                  animate={{
                    width: onHover ? "30px" : "0px",
                    height: onHover ? "30px" : "0px",
                  }}
                  transition={{ duration: 0.3 }}
                  style={{
                    width: "0px",
                    height: "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#F9837C",
                    borderRadius: "1000px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    padding: "2px",
                    transform: "translate(4px,4px)",
                    flexDirection: "column",
                  }}
                >
                  <IconX color="white" size={20} />
                </motion.div>
              )}
              <IconPencil color="white" />
              <motion.div
                style={{
                  marginTop: "2px",
                  fontSize: "12px",
                  fontWeight: "900",
                  display: "flex",
                  textAlign: "center",
                }}
                animate={{
                  display: onHover ? "flex" : "none",
                  color: "#FFF",
                  width: "70%",
                }}
              >
                {tn("Uploading avatar")}
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </FileButton>
      <div>
        <Text color="#4D4D4D" size="lg" fz={24} fw="700">
          {userData?.first_name?.split(" ")?.[0] + " " + userData?.last_name}
        </Text>
        <Text mt="sm" color="#4D4D4D"  fw="400" fz={17}>
          {fv(userData?.major, "major", translation)?.[locale]}
        </Text>
        <Text mt={2} color="#4D4D4D" fw="400" fz={17}>
          {fv(userData?.university, "university", translation)?.[locale]}
        </Text>
      </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          gap: "10px",
        }}
      >
        <Switch
          checked={discoverable}
          onChange={async (e) => {
            const record = pb
              .collection("applicants")
              .update(userData?.id, {
                discoverable: e.target.checked,
              })
              .then((res: any) => {
                setDiscoverable(res?.discoverable);
                setReloader(!reloader);
              });
          }}
          mx="sm"
          size="lg"
          color="yellow"
        />
        <Text mx="sm" color="#4D4D4D" size="md" fw="700">
          {tn("Discoverable")}
        </Text>
        </div>
      </div>
    </div>
  );
}
