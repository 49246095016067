import {
  Avatar,
  Button,
  Center,
  NativeSelect,
  NumberInput,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Textarea,
  rem,
} from "@mantine/core";
import { tn, vtn } from "../../translation";
import { useViewportSize } from "@mantine/hooks";
import { MIME_TYPES } from "@mantine/dropzone";
import { IconCheck, IconPhoto, IconSquareRoundedCheck, IconUpload } from "@tabler/icons-react";
import { useContext, useState, useEffect } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT, countries_numbers } from "../../constants";
import { useForm } from "@mantine/form";
// import { MainDataProfile } from "./mainDataProfile";
import { BannersContext } from "../../context/BannersContext";
import { AppContext } from "../../context/AppContext";
import { MainDataApplicant } from "./MainDataApplicant";
import { vf } from "../../main_components/Functions";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function ApplicantEdit({ setEditing, setWarnning , data}: any) {
  const [userData, setUserData] = useState(data);

  const [phoneNumber, setPhoneNumber]: any = useState("");
  const validate_text = [
    vtn("case_study"),
    vtn("first_name"),
    vtn("last_name"),
    vtn("taken_email"),
    vtn("email"),
    vtn("password"),
    vtn("password_comfirm"),
    vtn("arabic_name"),
    vtn("mobile"),
    vtn("university"),
    vtn("major"),
    vtn("GPA"),

  ];
  const form = useForm({
    initialValues: {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      gender: userData?.gender,
      mobile: userData?.mobile,
      about: userData?.about,
      case_study: userData?.case_study,
      university: userData?.university,
      major: userData?.major,
      GPA: parseFloat(userData?.GPA)??0,
      cv: userData?.CV,
      country_mobile_number: userData?.country_mobile_number,
    },
    validate: {
      first_name: (value) =>
      !/^\S+ \S+$/.test(value) && value.length > 2 && !/[0-9]/.test(value)
        ? /^[\u0621-\u064A\s]+$/.test(value)? null:validate_text?.[7]
        : validate_text?.[1],
        last_name: (value) =>
     /^[\u0621-\u064A\s]+$/.test(value)? null:validate_text?.[7],
         mobile:(value,values)=> (value.length == values?.country_mobile_number.length + 9 ? null :validate_text?.[8] ),
        university: (value) => !value ? validate_text?.[9] : null,
        major: (value) => !value ? validate_text?.[10] : null,
        GPA: (value) => value == 0 ? validate_text?.[11] : null,
        
    },
  });

  const { locale, translation }: any = useContext(TranslationContext);
 const {setTopBanner}:any = useContext(BannersContext)
 const {reloader,setReloader} = useContext(AppContext)

  const { height, width }: any = useViewportSize();
  const case_studies = vf("case_study");
  const universities = vf("university");
  const majors = vf("major");
  const [maxGPA, setMaxGPA] = useState(5);
  useEffect(() => {
    if (maxGPA == 4) {
      form.setFieldValue("GPA", 0);
    }
  }, [maxGPA]);
  useEffect(() => {
    if(phoneNumber.length >0){
    form.setFieldValue("mobile", "");
  }
  }, [form.values.country_mobile_number]);
  useEffect(() => {
    if(phoneNumber.length >0){
      form.setFieldValue("mobile", form.values.country_mobile_number + phoneNumber);
    }else{
      form.setFieldValue("mobile", userData?.mobile);
    }
   
  }, [phoneNumber]);
  const dialogMassage = tn("Data has been updated");

  return width > 10 ? (
    <form
      onSubmit={form.onSubmit((value) => {
        const data_ = {
          first_name: value?.first_name ,
          last_name: value?.last_name ,
          gender:
            value?.gender ,
          mobile: value?.mobile,
          about: value?.about,
          university: value?.university,
          major: value?.major,
          GPA: value?.GPA?value?.GPA:0,
          GPA_out_of: maxGPA,
          case_study: value?.case_study,
          country_mobile_number: value?.country_mobile_number,
        };
       
        const record = pb
          .collection("applicants")
          .update(userData?.id, data_)
          .then((records: any) => {
            // setBannerOpen(true);
            setTopBanner({
              message: dialogMassage,
              color: "#2DC071",
              top: "83px",
              backgroundColor: "#F2FFF7EB",
              icon: <IconSquareRoundedCheck/>,
            })
            setEditing(false);
            setReloader(!reloader)
            setUserData(pb?.authStore?.model);
          });
      })}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: width < 1400 ? "start" : "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          //   height: "100%",
          marginBottom: "20px",
          display: "flex",
          justifyContent: width < 1400 ? "start" : "center",
          alignItems: "center",
          flexDirection: width > 1400 ? "row" : "column",
        }}
      >
        <div
          style={{
            borderRadius: "10px",
            background: "#FFF",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
            padding: "10px",
            margin: "0px 10px",
            zIndex: 999,
            position: "absolute",
            top: width >= 420 ? "20px" : "75px",
            transform: width > 1400 ? "translateX(-520px)" : "translateX(0px)",
            left: width > 1400 ? "" : "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={!form.isDirty()}
            type="submit"
            mx="sm"
            // radius was 0 here
            style={
              form.isDirty()
                ? { background: "#69DCBF", height: "25px" }
                : { height: "25px" }
            }
          >
            {tn("Save")}
          </Button>

          <Button
            mx="sm"
            // radius was 0 here
            style={{ background: "#F9837C", height: "25px" }}
            onClick={() => {
              if (form.isDirty()) {
                setWarnning(true);
              } else {
                setEditing(false);
              }
            }}
          >
            {tn("Cancel")}
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: width > 600 ? "row" : "column",
            width: width > 1400 ? "540px" : "100%",
            maxWidth: "700px",
            marginBottom: width > 1400 ? "0px" : "10px",
            justifyContent: "space-between",
            // height: "100%",
          }}
        >
          <MainDataApplicant data={userData} />
          <div
            style={{
              borderRadius: "10px",
              background: "#FFF",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
              padding: "20px",
              width: width > 1400 ? "270px" : width > 600 ? "49%" : "100%",
              height: width > 600 ? "370px" : "270px",
              margin:
                width > 1400
                  ? "0px 10px"
                  : width > 600
                  ? ""
                  : "10px 0px 0px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Text fz="xl" fw="700" color="dimmed" pb={28} pt={10}>
              {tn("Overview of yourself")}
            </Text>
            <Textarea
              placeholder={tn("Write an overview of yourself")}
              {...form.getInputProps("about")}
              style={{
                width: "100%",
              }}
              styles={() => ({
                input: {
                  height: width > 600 ? "255px" : "150px",
                },
              })}
            />
          </div>
        </div>
        <div
          style={{
            borderRadius: "10px",
            background: "#FFF",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
            padding: "30px 10px",
            width: "100%",
            maxWidth: "700px",
            // margin: width > 1000 ? "0px 20px" : "20px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Text fz="xl" fw="700" color="dimmed" pb={30}>
            {tn("Personal information")}
          </Text>
          <div
            style={{
              width: "100%",
              maxWidth: "700px",
              // margin: width > 1000 ? "0px 20px" : "20px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: width > 650 ? "row" : "column",
            }}
          >
            <div
              style={{
                width: "100%",
                margin: "0px 10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("First Name")}
                </Text>
                <TextInput
                  size="xs"
                  
                  {...form.getInputProps("first_name")}
                  style={{
                    width: "60%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  // marginTop: "25px",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Mobile")}
                </Text>
              
              <TextInput

                // required
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={form.values.mobile?.replace(form.values.country_mobile_number, "")}
                type="number"
                error={form.errors.mobile}
                label={tn("Mobile")}
                rightSection={
                  <NativeSelect
                   {...form.getInputProps("country_mobile_number")}
                    // value={countryNum}
                    data={countries_numbers}
                    styles={(theme) => ({
                      label: {
                        color: "#ffffff",
                      },
                      input: {
                        fontSize: "13px",
                        border: "0px solid #69DCBF",
                        borderRadius: "0px",
                        color: "white",
                        backgroundColor: "transparent",
                        // borderBottom: "1px solid #69dcbf86",
                        "&:focus-within": {
                          // borderBottom: "1px solid #69DCBF",
                        }, 
                      },
                     
                    })}
                  />
                }
                rightSectionWidth={60}
                withAsterisk={false}
                styles={(theme) => ({
                  label: {
                    // marginLeft: "-30px",
                    color: "white",
                  },
                  input: {
                    // textAlign: "end",
                    // border: "0px solid #69DCBF",
                    // borderRadius: "0px",
                    // color: "white",
                    // backgroundColor: "transparent",
                    // borderBottom: "1px solid #69dcbf86",
                    // "&:focus-within": {
                    //   border: "0px solid #69DCBF",
                    //   borderBottom: "1px solid #69DCBF", 
                    // },
                   
                  }, error:{
                    marginBottom:"-13px",
                    fontSize:"12px",
                  }
                })}
                size="xs"
                style={{
                 width: "60%",
               }}
              />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Gender")}
                </Text>
                <SegmentedControl
                  size="xs"
                  {...form.getInputProps("gender")}
                  //   style={{height:"30px"}}
                  styles={() => ({
                    root: {
                      width: "60%",
                      color: "#737373",
                      backgroundColor: "transparent",
                      border: "1px solid #69dcbfdc",
                    },
                    label: {
                      color: "#737373",
                      height: "25px",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0px 10px",
                      "&:hover": {
                        color: "#737373",
                      },
                      '&[data-active="true"]': {
                        color: "#737373",
                      },
                      "&[data-active]:hover": {
                        color: "#737373",
                      },
                    },
                    indicator: {
                      color: "#737373",
                      backgroundColor: "#69DCBF !important",
                      borderRadius: "2px",
                    },
                    control: {
                      width: "100%",
                      color: "#737373",
                      //    backgroundColor: "#efefef5f !important",
                      borderRadius: "2px",
                    },
                    controlActive: { color: "#737373" },
                  })}
                  data={[
                    { label: tn("Male"), value: "male" },
                    { label: tn("Female"), value: "female" },
                  ]}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("University")}
                </Text>
                <Select
                  size="xs"
                  searchable
                  
                  data={universities?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))}
                  {...form.getInputProps("university")}
                  style={{ width: "60%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("GPA")}
                </Text>

                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <NumberInput
                    size="xs"
                    
                    mr="sm"
                    precision={2}
                    step={0.05}
                    style={{ width: "calc(100% - 10px)" }}
                    hideControls
                    withAsterisk={false}
                    {...form.getInputProps("GPA")}
                    max={maxGPA}
                  />
                  <div
                    style={{
                      border: "1px solid #69dcbfdc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "30px",
                    }}
                  >
                    <SegmentedControl
                      size="xs"
                      onChange={(value) => {
                        setMaxGPA(parseInt(value));
                        form.setFieldValue("GPA_out_of", value);
                      }}
                      style={{ width: "110%" }}
                      styles={() => ({
                        root: {
                          color: "#000000",
                          backgroundColor: "transparent",
                          // border: "1px solid #69dcbfdc",
                        },

                        indicator: {
                          color: "#000000",
                          backgroundColor: "#69DCBF !important",
                          borderRadius: "2px",
                        },
                        control: {
                          // width: "60px",
                          color: "#000000",
                          // backgroundColor: "#9191912b !important",
                          borderRadius: "2px",
                        },
                        controlActive: { color: "#000000" },
                      })}
                      data={[
                        {
                          label: locale == "en" ? "5" : "٥",
                          value: "5",
                        },
                        {
                          label: locale == "en" ? "4" : "٤",
                          value: "4",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                margin: "0px 10px",
                marginTop: width > 650 ? "0px" : "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Last Name")}
                </Text>
                <TextInput
                  size="xs"
                  
                  {...form.getInputProps("last_name")}
                  style={{
                    width: "60%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Email")}
                </Text>
                {/* <div style={boxStyle}>{data?.email}</div> */}
                <BoxStyleF text={userData?.email} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Case Study")}
                </Text>
                <Select
                  size="xs"
                  searchable
                  
                  data={case_studies?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))}
                  {...form.getInputProps("case_study")}
                  style={{ width: "60%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Major")}
                </Text>
                <Select
                  size="xs"
                  searchable
                  
                  data={majors?.map((major: any) => ({
                    value: major?.name,
                    label: major?.[locale],
                  }))}
                  {...form.getInputProps("major")}
                  style={{ width: "60%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("CV")}
                </Text>
               
                {userData?.cv ? (
                <a
                  href={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.cv}?token=`}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    onClick={async() => {
                      // downloadPDF(`${CMS_ENDPOINT}/api/files/${data?.collectionName}/${data?.id}/${data?.cv}?token=`,data?.cv)

                    }}
                    style={{
                      background: "#69DCBF",
                      borderRadius: "5.112px",
                      height: "30px",
                    }}
                  >
                    {tn("Download")}
                  </Button>
                 </a>
              ) : (
                <Text color="#676767" size="xs" fw="700">
                  {tn("No Data")}
                </Text>
              )}
              </div>{" "}
            
            </div>
          </div>
        </div>
      </div>
    </form>
  ) : (
    <></>
  );
}

const BoxStyleF: any = ({ text }: any) => {
  const boxStyle: any = {
    height: "1.875rem",
    boxSizing: "border-box",
    fontSize: text?.length > 15 ? "60%" : "14px",
    minWidth: "60%",
    maxWidth: "60%",
    color: "#727272",
    border: "0.0625rem solid #ced4da",
    backgroundColor: "#fff",
    paddingRight: "calc(2.25rem / 3)",
    paddingLeft: "calc(2.25rem / 3)",
    borderRadius: "0.25rem",
    display: "flex",
    alignItems: "center",
    // justifyContent: "end",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // wordWrap: "break-word",
    // maxHeight: "3.6em",
    // lineHeight: "1.8em",
  };
  return <div style={boxStyle}>{text}</div>;
};
