import { Icon } from "@iconify/react/dist/iconify.js";
import { tn } from "../../translation";
import { buttons_style, main_input_style } from "../../components_styles";
import { Button, Modal, TextInput } from "@mantine/core";
import { countries_numbers } from "../../constants";
import React, { useEffect, useRef, useState } from "react";
import { PersonalInformation } from "./components/PersonalInformation";
import { AcademicInformation } from "./components/AcademicInformation";
import { StyleForms } from "./components/StyleForms";
import { FormBlocks } from "./components/FormBlocks";
import { pb } from "../../config";
import { fv } from "../../main_components/Functions";
import { useNavigate } from "react-router-dom";

function CVform({
  id,
  setGPAfirst,
  GPAfirst,
  setMaxGPA,
  setPhoneNumber,
  form,
  lang,
}: any) {
  const [nameEdit, setNameEdit] = useState(false);
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [actionModal, setActionModal] = useState("");
  useEffect(() => {
    if (nameEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [nameEdit]);
  const navigate = useNavigate();
  return (
    <div className="w-[50%] h-full bg-white max-md:w-[100%] px-14 flex flex-col items-center overflow-auto max-md:px-4">
      <div className="max-w-[700px] w-full">
        <div className="flex justify-between items-center mt-10">
          <div />
          {!nameEdit ? (
            <div
              onClick={() => {
                setNameEdit(true);
              }}
              className="flex items-center gap-5 font-medium	cursor-pointer text-[#292929]"
            >
              {editIcon}
              <div>{form.values.cv_title || tn("untitled", lang)}</div>
            </div>
          ) : (
            <TextInput
              ref={inputRef}
              placeholder={tn("Title", lang)}
              styles={main_input_style(false, {
                input: {
                  maxHeight: "24px",
                  fontSize: "14px",
                },
              })}
              {...form.getInputProps("cv_title")}
              onBlur={() => {
                setNameEdit(false);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  inputRef.current.blur(); // Step 3: Blur the input when Enter is pressed
                }
              }}
            />
          )}
          <Icon
            onClick={() => {
              navigate("/applicant/profile");
            }}
            className="text-[#292929] cursor-pointer text-2xl"
            icon={lang == "ar" ? "ep:arrow-left-bold" : "ep:arrow-right-bold"}
          />
        </div>

        <PersonalInformation
          form={form}
          tn={tn}
          setPhoneNumber={setPhoneNumber}
          countries_numbers={countries_numbers}
          lang={lang}
          id={id}
        />
        {/* <AcademicInformation
          form={form}
          tn={tn}
          setGPAfirst={setGPAfirst}
          GPAfirst={GPAfirst}
          setMaxGPA={setMaxGPA}
          lang={lang}
        /> */}

        <FormBlocks form={form} tn={tn} lang={lang} />
        <StyleForms form={form} tn={tn} lang={lang} />
        <div className="w-full mt-10 flex items-center gap-6 mb-3 md:mb-32">
          <Button
            loading={loading}
            style={{
              width: "200px",
            }}
            styles={buttons_style("filled-green")}
            leftIcon={
              id ? (
                <Icon icon="eva:save-outline" />
              ) : (
                <Icon icon="stash:plus-solid" />
              )
            }
            size="lg"
            onClick={async () => {
              setLoading(true);
              const formData = new FormData();
              formData.append("data", JSON.stringify(form?.values));
              formData.append("title", form.values.cv_title);
              formData.append("lang", lang);
              formData.append("applicant", pb?.authStore?.model?.id)
              if(form.values.profile_picture){
              if(typeof form.values.profile_picture != 'string'){
              formData.append("profile_picture", form.values.profile_picture);
              }
            }else{
              formData.append("profile_picture", "");
            }

              if (id) {
                const record = await pb.collection("applicants_cv").update(id, formData);
              } else {
                const record = await pb.collection("applicants_cv").create(formData);
                navigate("/applicant/cv_generator/" +lang+"/"+ record.id);
              }
              setLoading(false);
            setActionModal(id ? "updated" : "created");
            }}
          >
            {id ? tn("Save", lang) : tn("Create", lang)}
          </Button>
          <Button
            style={{
              width: "200px",
            }}
            styles={buttons_style("outline-red")}
            size="lg"
            onClick={() => {
              navigate("/applicant/profile");
            }}
          >
            {tn("Cancel", lang)}
          </Button>
        </div>
        <Button
        className="md:hidden mb-32"
          style={{
            width: "100%",
          }}
          size="lg"
          loading={downloadLoading}
          leftIcon={icon1}
          styles={buttons_style("filled-main")}
          onClick={async () => {
            setDownloadLoading(true);
            try {
              const htmlContent = document.getElementById("template1")?.innerHTML;
              if (!htmlContent) {
                throw new Error("No HTML content found");
              }

              const response = await fetch("https://functions.coophub.co/html_to_pdf", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  html: htmlContent,
                  cv_size: form?.values?.cv_size || "A4",
                }),
              });

              if (!response.ok) {
                throw new Error("Failed to generate PDF");
              }

              // Download the PDF
              const blob = await response.blob();
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = form?.values?.cv_title || "coophub_cv";
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url); // Clean up the URL once it's no longer needed
              document.body.removeChild(a); // Clean up the <a> element after download
            } catch (error) {
              console.error("Error generating PDF:", error);
            } finally {
              setDownloadLoading(false);
            }
          }}
        >
          {tn("Download as PDF",lang)}
        </Button>
      </div>
      <Modal
        opened={!!actionModal}
        onClose={() => {
          setActionModal("");
        }}
        // size="sm"
        withCloseButton={false}
        centered
        styles={{
          body: {
            padding: "0", 
          },
          // make it rounded
          content: {
            borderRadius: "20px",
            padding: "0",
          },
        }}
      >
        <div
        className="w-full flex flex-col items-center justify-start p-8"
        style={{
          backgroundImage: `url(/green_pattrn.png)`,
          backgroundSize: "cover",
        }}
        >
          <div className="w-[140px] h-[140px] bg-[#69dcbf3f] rounded-[20px] flex items-center justify-center">
            <Icon icon="carbon:checkmark-outline" className="text-8xl text-[#69dcbf]" />
          </div>
          <div className="text-[#292929] text-2xl font-medium mt-10">
            {
              actionModal == "created" ? tn("resume_created", lang) : tn("resume_saved", lang)
            }
          </div>
          <div className="mt-10 flex items-center justify-center w-full gap-4">
          <Button
              style={{
                width: "100%",
              }}
              size="xl"
              styles={buttons_style("filled-red")}
              onClick={() => {
                setActionModal("");
              }}
            >
              {tn("close", lang)}
            </Button>
            <Button
              style={{
                width: "100%",
              }}
              size="xl"
              styles={buttons_style("filled-green")}
              onClick={() => {
                setActionModal("");
                navigate("/applicant/profile");
              }}
            >
              {tn("return_to_profile", lang)}
            </Button>
          

          </div>
        </div>
      </Modal>

    </div>
  );
}

export default React.memo(CVform);

const editIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 25 22"
    fill="none"
  >
    <path
      d="M16.8364 4.14751L20.5797 7.46828M7.24997 6.65788H3.31249C2.9644 6.65788 2.63056 6.78056 2.38442 6.99892C2.13828 7.21728 2 7.51344 2 7.82225V19.4659C2 19.7747 2.13828 20.0709 2.38442 20.2892C2.63056 20.5076 2.9644 20.6303 3.31249 20.6303H17.7499C18.098 20.6303 18.4318 20.5076 18.678 20.2892C18.9241 20.0709 19.0624 19.7747 19.0624 19.4659V14.2263M22.2242 2.6874C22.4701 2.90549 22.6652 3.16443 22.7983 3.44942C22.9314 3.73441 22.9999 4.03988 22.9999 4.34836C22.9999 4.65685 22.9314 4.96231 22.7983 5.24731C22.6652 5.5323 22.4701 5.79124 22.2242 6.00933L13.2415 13.9783L8.56246 14.8084L9.49827 10.6575L18.481 2.68856C18.7266 2.47028 19.0184 2.29712 19.3395 2.17897C19.6607 2.06082 20.0049 2 20.3526 2C20.7003 2 21.0445 2.06082 21.3657 2.17897C21.6868 2.29712 21.9786 2.47028 22.2242 2.68856V2.6874Z"
      stroke="#FFB004"
      stroke-width="2.62499"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const icon1 = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      d="M17.875 2.75H4.125C3.76033 2.75 3.41059 2.89487 3.15273 3.15273C2.89487 3.41059 2.75 3.76033 2.75 4.125V17.875C2.75 18.2397 2.89487 18.5894 3.15273 18.8473C3.41059 19.1051 3.76033 19.25 4.125 19.25H17.875C18.2397 19.25 18.5894 19.1051 18.8473 18.8473C19.1051 18.5894 19.25 18.2397 19.25 17.875V4.125C19.25 3.76033 19.1051 3.41059 18.8473 3.15273C18.5894 2.89487 18.2397 2.75 17.875 2.75ZM17.875 4.125V13.0625H15.4086C15.2281 13.062 15.0493 13.0974 14.8826 13.1666C14.7159 13.2358 14.5646 13.3374 14.4375 13.4655L12.778 15.125H9.22195L7.5625 13.4655C7.43528 13.3373 7.28384 13.2356 7.11699 13.1664C6.95013 13.0973 6.77118 13.0619 6.59055 13.0625H4.125V4.125H17.875ZM17.875 17.875H4.125V14.4375H6.59055L8.25 16.097C8.37722 16.2252 8.52866 16.3269 8.69551 16.3961C8.86237 16.4652 9.04132 16.5006 9.22195 16.5H12.778C12.9587 16.5006 13.1376 16.4652 13.3045 16.3961C13.4713 16.3269 13.6228 16.2252 13.75 16.097L15.4095 14.4375H17.875V17.875ZM7.76359 10.7989C7.63459 10.6699 7.56212 10.4949 7.56212 10.3125C7.56212 10.1301 7.63459 9.9551 7.76359 9.82609C7.8926 9.69709 8.06756 9.62462 8.25 9.62462C8.43244 9.62462 8.6074 9.69709 8.73641 9.82609L10.3125 11.403V6.1875C10.3125 6.00516 10.3849 5.8303 10.5139 5.70136C10.6428 5.57243 10.8177 5.5 11 5.5C11.1823 5.5 11.3572 5.57243 11.4861 5.70136C11.6151 5.8303 11.6875 6.00516 11.6875 6.1875V11.403L13.2636 9.82609C13.3275 9.76222 13.4033 9.71155 13.4868 9.67698C13.5702 9.64241 13.6597 9.62462 13.75 9.62462C13.8403 9.62462 13.9298 9.64241 14.0132 9.67698C14.0967 9.71155 14.1725 9.76222 14.2364 9.82609C14.3003 9.88997 14.351 9.9658 14.3855 10.0493C14.4201 10.1327 14.4379 10.2222 14.4379 10.3125C14.4379 10.4028 14.4201 10.4923 14.3855 10.5757C14.351 10.6592 14.3003 10.735 14.2364 10.7989L11.4864 13.5489C11.4226 13.6128 11.3467 13.6635 11.2633 13.6981C11.1798 13.7327 11.0903 13.7505 11 13.7505C10.9097 13.7505 10.8202 13.7327 10.7367 13.6981C10.6533 13.6635 10.5774 13.6128 10.5136 13.5489L7.76359 10.7989Z"
      fill="white"
    />
  </svg>
);
