import React from 'react';
import { tn } from '../../../translation';
import A4pages from '../components/A4pages';

export const CVtemplate3 = ({ data, lang }: any) => {
  return (
    <A4pages data={data} layout={<CVtemplate3Layout data={data} lang={lang} />} main_style={{
      width: 'calc(100% - 300px)',
      marginLeft: lang=="en"?'300px':"0",
      marginRight: lang=="ar"?'300px':"0",
    }}> 

 
        {data.experiences?.[0]&&  <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Experiences', lang)}</h2>
          </div>}
          <div style={{height:"20px"}}/>
          {data.experiences.map((experience, index) => (
            <div key={index} style={{ paddingBottom: '25px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{experience.job_title}</h3>
                <p style={{ fontSize: '14px', color: '#999' }}>
                  {experience.start_date && new Date(experience.start_date).toLocaleDateString()}{" "}
                  -{" "}
                  {experience?.until_now?
                  tn("Until now",lang)
                  :(experience.end_date && new Date(experience.end_date).toLocaleDateString())}
                </p>
              </div>
              <p style={{ fontSize: '14px', color: '#666' }}>{experience.company} - {experience.city}, {experience.country}</p>
              <div
                dangerouslySetInnerHTML={{ __html: experience.description }}
                style={{ fontSize: '14px', color: '#444' }}
              ></div>
            </div>
          ))}

        {/* Education Section */}
         {data.education?.[0] && <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Education', lang)}</h2>
          </div>}
         
          {data.education.map((education_, index) => (
            <div key={index} style={{ paddingBottom: '20px' }}>
              <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{education_.university}</h3>
              <p style={{ fontSize: '14px', color: '#666' }}>{education_.major}</p>
              <p style={{ fontSize: '14px', color: '#999' }}>
                {education_.graduation_date && new Date(education_.graduation_date).toLocaleDateString()}
              </p>
              <p style={{ fontSize: '14px', color: '#999' }}>{education_.GPA}</p>
            </div>
          ))}

        {/* Skills Section */}
         {data.skills?.[0] && <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Skills', lang)}</h2>
          </div>}
          <div style={{height:"20px"}}/>
          <ul style={{ fontSize: '14px', listStyleType: 'circle', padding: '0 20px', color: '#555' }}>
            {data.skills.map((skill, index) => (
              <li key={index} style={{ paddingBottom: '8px' }}>
                <span style={{ fontWeight: '600' }}>{skill.skill}</span> - {skill.level}
              </li>
            ))}
          </ul>

        {/* Certificates Section */}
         {data.certificates?.[0]&& <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Certificates and Courses', lang)}</h2>
          </div>}
          <div style={{height:"20px"}}/>
          {data.certificates.map((certificate, index) => (
            <div key={index} style={{ paddingBottom: '10px' }}>
              <p style={{ fontSize: '14px', color: '#555' }}>
                <strong>{certificate.title}</strong> - {certificate.institute}
              </p>
              <p style={{ fontSize: '14px', color: '#999' }}>
                {certificate.date && new Date(certificate.date).toLocaleDateString()}
              </p>
            </div>
          ))}

        {/* Languages Section */}
         {data.languages?.[0]&& <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>{tn('Languages', lang)}</h2>
          </div>}
          <div style={{height:"20px"}}/>
          <ul style={{ fontSize: '14px', paddingLeft: '20px', color: '#555' }}>
            {data.languages.map((language, index) => (
              <li key={index} style={{ paddingBottom: '8px' }}>
                {language.language} - {language.level}
              </li>
            ))}
          </ul>
    </A4pages>
  );
};

// Section title style
const sectionTitleStyle = (color: string) => ({
  fontWeight: 'bold',
  fontSize: '22px',
  color: color || '#007acc',
  borderBottom: `3px solid ${color || '#007acc'}`,
  paddingBottom: '5px',
});
export const CVtemplate3Layout = ({ data,lang }: any) => {
  return (
   
<aside
        style={{
          backgroundColor: data?.cv_color || '#007acc',
          color: '#fff',
          width: '300px',
          padding: '30px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '100%',
        }}
      >
        {/* Profile Image */}
        {data?.profile_picture && (
          <div
            style={{
              borderRadius: '50%',
              width: '150px',
              height: '150px',
              overflow: 'hidden',
              marginBottom: '20px',
              border: '4px solid #fff',
            }}
          >
            <img
              src={
                typeof data?.profile_picture === "string"
                  ? data?.profile_picture
                  : URL?.createObjectURL(data?.profile_picture)
              }
              alt="Profile"
              style={{ width: '100%', height: '100%', objectFit: 'cover',              backgroundColor: '#fff',
            }}
            />
          </div>
        )}

        {/* Contact Info */}
        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
          <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
            {data.first_name} {data.last_name}
          </h1>
          <div
                dangerouslySetInnerHTML={{ __html: data.career_summary }}
                  style={{  fontSize: '16px',  marginBottom: '20px' }}
              ></div>
          
        </div>

        <div style={{ width: '100%', textAlign: 'center' }}>
          <p style={{ fontSize: '14px', marginBottom: '10px' }}>
            ✉️ <a href={`mailto:${data.email}`} style={{ color: '#fff', textDecoration: 'none' }}>{data.email}</a>
          </p>
          <p style={{ fontSize: '14px' }}>
            📞 <a href={`tel:${data.mobile}`} style={{ color: '#fff', textDecoration: 'none' }}>{data.mobile}</a>
          </p>
        </div>
      </aside>
  );
};

export const CVtemplate3_content = ({ data, lang }: any) => {
  return (
    <>

 
        {data.experiences?.[0]&&  <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>
              {/* {tn('Experiences', lang)} */}
              {
                lang == "ar" ? "الخبرات" : "Experiences"
              }
              </h2>
          </div>}
          <div style={{height:"20px"}}/>
          {data.experiences.map((experience, index) => (
            <div key={index} style={{ paddingBottom: '25px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{experience.job_title}</h3>
                <p style={{ fontSize: '14px', color: '#999' }}>
                  {experience.start_date && new Date(experience.start_date).toLocaleDateString()}{" "}
                  -{" "}
                  {experience?.until_now?
                 lang == "ar" ? "حتى الآن" : "Present"
                  :(experience.end_date && new Date(experience.end_date).toLocaleDateString())}
                </p>
              </div>
              <p style={{ fontSize: '14px', color: '#666' }}>{experience.company} - {experience.city}, {experience.country}</p>
              <div
                dangerouslySetInnerHTML={{ __html: experience.description }}
                style={{ fontSize: '14px', color: '#444' }}
              ></div>
            </div>
          ))}

        {/* Education Section */}
         {data.education?.[0] && <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>
              {/* {tn('Education', lang)} */}
              {
                lang == "ar" ? "التعليم" : "Education"
              }
              </h2>
          </div>}
         
          {data.education.map((education_, index) => (
            <div key={index} style={{ paddingBottom: '20px' }}>
              <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{education_.university}</h3>
              <p style={{ fontSize: '14px', color: '#666' }}>{education_.major}</p>
              <p style={{ fontSize: '14px', color: '#999' }}>
                {education_.graduation_date && new Date(education_.graduation_date).toLocaleDateString()}
              </p>
              <p style={{ fontSize: '14px', color: '#999' }}>{education_.GPA}</p>
            </div>
          ))}

        {/* Skills Section */}
         {data.skills?.[0] && <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>
              {/* {tn('Skills', lang)} */}
              {
                lang == "ar" ? "المهارات" : "Skills"
              }
              </h2>
          </div>}
          <div style={{height:"20px"}}/>
          <ul style={{ fontSize: '14px', listStyleType: 'circle', padding: '0 20px', color: '#555' }}>
            {data.skills.map((skill, index) => (
              <li key={index} style={{ paddingBottom: '8px' }}>
                <span style={{ fontWeight: '600' }}>{skill.skill}</span> - {skill.level}
              </li>
            ))}
          </ul>

        {/* Certificates Section */}
         {data.certificates?.[0]&& <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>
              {/* {tn('Certificates and Courses', lang)} */}
              {
                lang == "ar" ? "الشهادات والدورات" : "Certificates and Courses"
              }
            </h2>
          </div>}
          <div style={{height:"20px"}}/>
          {data.certificates.map((certificate, index) => (
            <div key={index} style={{ paddingBottom: '10px' }}>
              <p style={{ fontSize: '14px', color: '#555' }}>
                <strong>{certificate.title}</strong> - {certificate.institute}
              </p>
              <p style={{ fontSize: '14px', color: '#999' }}>
                {certificate.date && new Date(certificate.date).toLocaleDateString()}
              </p>
            </div>
          ))}

        {/* Languages Section */}
         {data.languages?.[0]&& <div style={sectionTitleStyle(data?.cv_color)}>
            <h2>
              {/* {tn('Languages', lang)} */}
              {
                lang == "ar" ? "اللغات" : "Languages"
              }
              </h2>
          </div>}
          <div style={{height:"20px"}}/>
          <ul style={{ fontSize: '14px', paddingLeft: '20px', color: '#555' }}>
            {data.languages.map((language, index) => (
              <li key={index} style={{ paddingBottom: '8px' }}>
                {language.language} - {language.level}
              </li>
            ))}
          </ul>
    </>
  );
};

// Section title style