import {
  Button,
  NativeSelect,
  Select,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { tn, vtn } from "../../../../translation";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../context/TranslationContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../constants";
import { AppContext } from "../../../../context/AppContext";

import {
  containsArabicNumbers,
  convertArabicNumbersToEnglish,
  convertEnglishNumbersToArabic,
  vf,
} from "../../../../main_components/Functions";
import { styled } from "styled-components";
import { SegmentedControl } from "@mantine/core";
import { useForm } from "@mantine/form";
import { BannersContext } from "../../../../context/BannersContext";
import { IconSquareRoundedCheck } from "@tabler/icons-react";
import {
  buttons_style,
  main_input_style,
  main_no_input_style,
} from "../../../../components_styles";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function AcademicDataEdit({ setEditing }: any) {
  const [userData, setUserData] = useState(pb.authStore.model);

  const validate_text = [
    vtn("case_study"),
    vtn("first_name"),
    vtn("last_name"),
    vtn("taken_email"),
    vtn("email"),
    vtn("password"),
    vtn("password_comfirm"),
    vtn("arabic_name"),
    vtn("mobile"),
    vtn("university"),
    vtn("major"),
    vtn("GPA"),
    vtn("graduation_year"),
    vtn("semester"),
    vtn("degree"),
  ];
  const form = useForm({
    initialValues: {
      case_study: userData?.case_study,
      university: userData?.university,
      major: userData?.major,
      GPA: parseFloat(userData?.GPA) ?? 0,
      cv: userData?.CV,
      country_mobile_number: userData?.country_mobile_number,
      graduation_year: userData?.graduation_year,
      semester: userData?.semester,
      GPA_out_of: userData?.GPA_out_of,
      degree: userData?.degree,
    },
    validate: {
      university: (value) => (!value ? validate_text?.[9] : null),
      major: (value) => (!value ? validate_text?.[10] : null),
      GPA: (value) => (value <= 0 ? validate_text?.[11] : null),
      graduation_year: (value) => (!value ? validate_text?.[12] : null),
      semester: (value) => (!value ? validate_text?.[13] : null),
      degree: (value) => (!value ? validate_text?.[14] : null),
    },
  });

  const { locale, translation }: any = useContext(TranslationContext);
  const { setTopBanner }: any = useContext(BannersContext);
  const { reloader, setReloader } = useContext(AppContext);

  const case_studies = vf("case_study");
  const universities = vf("university");
  const majors = vf("major");
  const semesters = vf("semester");
  const degrees = vf("degree");
  const graduation_years = vf("graduation_year");

  const [maxGPA, setMaxGPA] = useState(userData?.GPA_out_of);

  const [GPAfirst, setGPAfirst] = useState(
    locale == "en"
      ? userData?.GPA.toString()
      : convertEnglishNumbersToArabic(userData?.GPA.toString())
  );

  console.log("GPAfirst", GPAfirst);
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(count + 1);
    if (count > 0) {
      form.setFieldValue("GPA", null);
      setGPAfirst("");
    }
  }, [maxGPA]);
  useEffect(() => {
    const formatted = convertArabicNumbersToEnglish(GPAfirst);
    const value: any = !isNaN(formatted) ? parseFloat(formatted) : null;
    if (value > 0) {
      if (value > maxGPA) {
        form.setFieldValue("GPA", maxGPA);
        setGPAfirst(
          !containsArabicNumbers(GPAfirst)
            ? maxGPA.toString()
            : convertEnglishNumbersToArabic(maxGPA.toString())
        );
      } else {
        form.setFieldValue("GPA", value);
      }
    } else {
      form.setFieldValue("GPA", null);
    }
  }, [GPAfirst]);

  const dialogMassage = tn("Data has been updated");

  return (
    <form
      onSubmit={form.onSubmit((value) => {
        const data_ = {
          university: value?.university,
          major: value?.major,
          GPA: value?.GPA ? value?.GPA : 0,
          GPA_out_of: maxGPA,
          case_study: value?.case_study,
          country_mobile_number: value?.country_mobile_number,
          graduation_year: value.graduation_year,
          semester: value.semester,
          degree: value.degree,
        };

        const record = pb
          .collection("applicants")
          .update(userData?.id, data_)
          .then(async (records: any) => {
            // setBannerOpen(true);
            setTopBanner({
              message: dialogMassage,
              color: "#2DC071",
              top: "83px",
              backgroundColor: "#F2FFF7EB",
              icon: <IconSquareRoundedCheck />,
            });
            setEditing(false);
            const authData = await pb.collection("applicants").authRefresh();
            setReloader(!reloader);
            setUserData(pb?.authStore?.model);
          });
      })}
      style={{
        width: "100%",
        //   height: "100%",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          color: "#5BB9BF",
          width: "100%",
        }}
      >
        <Button
          type="submit"
          color="green"
          styles={buttons_style("light-green")}
        >
          {tn("Save")}
        </Button>
        <Button
          color="red"
          styles={buttons_style("light-red")}
          onClick={() => setEditing(false)}
        >
          {tn("Cancel")}
        </Button>
      </div>
      <div>
        <Width1300
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              width: "100%",
            }}
          >
            <Select
              style={{ width: "100%" }}
              label={tn("Case Study")}
              searchable
              data={case_studies?.map((option: any) => ({
                value: option?.name,
                label: option?.[locale],
              }))}
              {...form.getInputProps("case_study")}
              styles={main_input_style(form.errors.case_study)}
            />

            <Select
              style={{ width: "100%" }}
              label={tn("University")}
              searchable
              data={universities?.map((option: any) => ({
                value: option?.name,
                label: option?.[locale],
              }))}
              {...form.getInputProps("university")}
              styles={main_input_style(form.errors.university)}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              width: "100%",
            }}
          >
            <Select
              style={{ width: "100%" }}
              label={tn("Degree")}
              searchable
              data={degrees?.map((option: any) => ({
                value: option?.name,
                label: option?.[locale],
              }))}
              {...form.getInputProps("degree")}
              styles={main_input_style(form.errors.degree)}
            />

            <Select
              label={tn("Major")}
              style={{ width: "100%" }}
              searchable
              data={majors?.map((option: any) => ({
                value: option?.name,
                label: option?.[locale],
              }))}
              {...form.getInputProps("major")}
              styles={main_input_style(form.errors.major)}
            />
          </div>
        </Width1300>
        <Width1300
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              <TextInput
                label={tn("GPA")}
                styles={main_input_style(form.errors.GPA)}
                mr="sm"
                error={form.errors.GPA}
                onBlur={(e) => {
                  if (!form?.values?.GPA) {
                    setGPAfirst("");
                  }
                }}
                onChange={(e) => {
                  setGPAfirst(e.target.value);
                }}
                value={GPAfirst}
                style={{ width: "calc(100% - 10px)" }}
                withAsterisk={false}
              />
              <div
                style={{
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                  marginBottom: "7px",
                }}
              >
                <SegmentedControl
                  onChange={(value) => {
                    setMaxGPA(parseInt(value));
                    form.setFieldValue("GPA_out_of", value);
                  }}
                  defaultValue={form?.values?.GPA_out_of}
                  styles={() => ({
                    root: {
                      color: "#737373",
                      backgroundColor: "transparent",
                      border: "1px solid rgba(7, 1, 13, 0.50)",
                      borderRadius: "7px",
                    },
                    label: {
                      color: "#737373",
                      height: "25px",
                      borderRadius: "7px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0px 10px",
                      "&:hover": {
                        color: "#737373",
                      },
                      '&[data-active="true"]': {
                        color: "#ffffff",
                      },
                      "&[data-active]:hover": {
                        color: "#ffffff",
                      },
                    },
                    indicator: {
                      color: "#737373",
                      backgroundColor: "#21033F !important",
                      borderRadius: "7px",
                    },
                    control: {
                      width: "100%",
                      color: "#737373",
                      //    backgroundColor: "#efefef5f !important",
                      borderRadius: "7px",
                    },
                    controlActive: { color: "#737373" },
                  })}
                  data={[
                    {
                      label: locale == "en" ? "5" : "٥",
                      value: "5",
                    },
                    {
                      label: locale == "en" ? "4" : "٤",
                      value: "4",
                    },
                  ]}
                />
              </div>
            </div>
            <Select
              label={tn("Graduation semester")}
              style={{ width: "100%" }}
              searchable
              data={semesters?.map((option: any) => ({
                value: option?.name,
                label: option?.[locale],
              }))}
              {...form.getInputProps("semester")}
              styles={main_input_style(form.errors.semester)}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              width: "100%",
            }}
          >
            <Select
              label={tn("Graduation Year")}
              style={{ width: "100%" }}
              searchable
              data={
                graduation_years?.[0]
                  ? graduation_years?.map((option: any) => ({
                      value: option?.name,
                      label: option?.[locale],
                    }))
                  : []
              }
              {...form.getInputProps("graduation_year")}
              styles={main_input_style(form.errors.graduation_year)}
            />

            <TextInput
              label={tn("University number")}
              style={{ width: "100%" }}
              {...form.getInputProps("university_number")}
              styles={main_input_style(form.errors.university_number)}
            />
          </div>
        </Width1300>
      </div>
    </form>
  );
}

const Width1300 = styled.div`
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;
