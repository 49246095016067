import { SegmentedControl, ColorInput, Select } from "@mantine/core";
import React, { useMemo } from "react";
import { main_input_style } from "../../../components_styles";
import { Icon } from "@iconify/react/dist/iconify.js";

export const StyleForms = React.memo(({ lang, form, tn }: any) => {

  return (
    <div>
      <div
        className="flex justify-start items-center gap-4 mt-10"
        style={{
          // force the icon to be the same color as the text
          color: form.values.cv_color,
        }}
      >
        <div
        className="h-[2px] w-[40%] rounded-[2px]"
        style={{
          backgroundColor: form.values.cv_color,
        }}
        />
        <Icon icon="mdi:paint-outline" className="text-[30px]" />
        {/* {icon(form.values.cv_color)} */}
        <div className="flex items-end gap-1">
          <div className="text-[25px] font-semibold">{tn("Style", lang)}</div>
        </div>
        <div
        className="h-[2px] w-[40%] rounded-[2px]"
        style={{
          backgroundColor: form.values.cv_color,
        }}
        />
      </div>
      <div className="w-full flex items-center justify-between gap-2 mt-5">
        <SegmentedControl
        size="xl"
          {...form.getInputProps("cv_size")}
          styles={() => ({
            root: {
              minWidth: "130px",
              color: "#737373",
              backgroundColor: "transparent",
              border: "2px solid "+(form.values.cv_color || "#21033F"),
              borderRadius: "15px",
            },
            label: {
              color: "#737373",
              borderRadius: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 10px",
              "&:hover": {
                color: "#737373",
              },
              '&[data-active="true"]': {
                color: "#ffffff",
              },
              "&[data-active]:hover": {
                color: "#ffffff",
              },
            },
            indicator: {
              color: "#737373",
              backgroundColor:
                (form.values.cv_color || "#21033F") + " !important",
              borderRadius: "12px",
            },
            control: {
              width: "100%",
              color: "#737373",
              //    backgroundColor: "#efefef5f !important",
              borderRadius: "12px",
            },
            controlActive: { color: "#737373" },
          })}
          data={[
            // letter or A4
            {
              label: tn("Letter", lang),
              value: "Letter",
            },
            {
              label: "A4",
              value: "A4",
            },
          ]}
        />
        <Select
         className=" w-[40%]"
         {...form.getInputProps("font_type")}
         placeholder={tn("font type", lang)}
         styles={main_input_style(false,{
          input: {
            borderRadius: "15px",
            border: "2px solid " + (form.values.cv_color || "#21033F"),
            backgroundColor: "transparent",
            "&:focus": {
              border: "2px solid " + (form.values.cv_color || "#21033F")
            }
          },
        })}
         data={[
           {
             label: "Tahoma",
             value: "Tahoma",
           },
           {
             label: "Times New Roman",
             value: "Times New Roman",
           },
           {
             label: "Courier New",
             value: "Courier New",
           },
           {
             label: "Verdana",
             value: "Verdana",
           },
           {
             label: "Georgia",
             value: "Georgia",
           },
           {
             label: "Comic Sans MS",
             value: "Comic Sans MS",
           },
           {
             label: "Impact",
             value: "Impact",
           },
           {
             label: "Lucida Console",
             value: "Lucida Console",
           },
         ]}
        />
        <ColorInput
          {...form.getInputProps("cv_color")}
          className=" w-full"
          styles={main_input_style(false,{
            input: {
              borderRadius: "15px",
              border: "2px solid " + (form.values.cv_color || "#21033F"),
              backgroundColor: "transparent",
              "&:focus": {
                border: "2px solid " + (form.values.cv_color || "#21033F")
              }
            },
          })}
          onChange={() => {}}
          onChangeEnd={(value) => {
            form.setFieldValue("cv_color", value);
          }}
          format="hex"
          swatches={[
            "#2e2e2e",
            "#868e96",
            "#fa5252",
            "#e64980",
            "#be4bdb",
            "#7950f2",
            "#4c6ef5",
            "#228be6",
            "#15aabf",
            "#12b886",
            "#40c057",
            "#82c91e",
            "#fab005",
            "#fd7e14",
          ]}
        />
      </div>{" "}

      <div style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(225px, 1fr))",
        gap: "10px",
        marginTop: "20px",
      }}>
        <div className="flex items-center justify-center">
        <div style={{
           width: "225px",
            height: "335px",
            backgroundColor: "#dcdcdc",
            borderRadius: "10px",
            border: "3px solid " +  (form.values.cv_template == 1 ? form.values.cv_color:"#d6d6d6"),
            backgroundImage: "url('/cv_images/template1.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            form.setFieldValue("cv_template", 1);
          }}
          />

        </div>
        <div className="flex items-center justify-center">
          <div style={{
           width: "225px",
            height: "335px",
            backgroundColor: "#dcdcdc",
            borderRadius: "10px",
            border: "3px solid " + (form.values.cv_template == 2 ? form.values.cv_color:"#d6d6d6"),
            backgroundImage: "url('/cv_images/template2.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            form.setFieldValue("cv_template", 2);
          }}
          />

          </div>
          <div className="flex items-center justify-center">
          <div style={{
           width: "225px",
            height: "335px",
            backgroundColor: "#dcdcdc",
            borderRadius: "10px",
            border: "3px solid " +  (form.values.cv_template == 3 ? form.values.cv_color:"#d6d6d6"),
            backgroundImage: "url('/cv_images/template3.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            form.setFieldValue("cv_template", 3);
          }}
          />

          </div>
      </div>
    </div>
  );
});
