import React from 'react';
import { tn } from '../../../translation';
import A4pages from '../components/A4pages';

export const CVtemplate2 = ({ data, lang,  }: any) => {
  return (
    <A4pages data={data} layout={<CVtemplate2Layout />}> 
   
  
      {/* Profile Section */}
      <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '40px' }}>
        {data?.profile_picture && (
          <div
            style={{
              borderRadius: '50%',
              width: '120px',
              minWidth: '120px',
              height: '120px',
              overflow: 'hidden',
              marginRight: lang === "ar" ? '0' : '30px',
              marginLeft: lang === "ar" ? '30px' : '0',
              boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
            }}
          >
            <img
              src={
                typeof data?.profile_picture === "string"
                  ? data?.profile_picture
                  : URL?.createObjectURL(data?.profile_picture)
              }
              alt="Profile"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
        )}
        <div>
          <h1
            style={{
              fontSize: '28px',
              fontWeight: '600',
              color: data?.cv_color || '#007acc',
            }}
          >
            {data.first_name} {data.last_name}
          </h1>
          {/* <p style={{ fontSize: '16px', color: '#777' }}>{data.about}</p> */}
          <div
                dangerouslySetInnerHTML={{ __html: data.career_summary }}
                  style={{  fontSize: '16px', color: '#555' }}
              ></div>
        </div>
      </div>

      {/* Contact Info */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#f1f1f1',
          padding: '10px 20px',
          borderRadius: '8px',
          paddingBottom: '30px',
        }}
      >
        <a href={`mailto:${data.email}`} style={{ color: '#007acc', textDecoration: 'none' }}>
          ✉️ {data.email}
        </a>
        <a href={`tel:${data.mobile}`} style={{ color: '#007acc', textDecoration: 'none' }}>
          📞 {data.mobile}
        </a>
      </div>

      {/* Experience Section */}
      {/* <section> */}
      {data.experiences?.[0] &&  <div style={sectionTitleWithLineStyle(data?.cv_color)}>
      <div style={lineStyle(data?.cv_color)} />

          <h2>{tn('Experiences', lang)}</h2>
        </div>}
        {data.experiences.map((experience, index) => (
          <div key={index} style={sectionContentStyle}>
            <div style={sectionTitleStyle}>
              <h3 style={{ fontSize: '15px', fontWeight: 'bold' }}>
                {experience.job_title}
              </h3>
              <p style={{ fontSize: '12px', color: '#999' }}>
                {experience.start_date && new Date(experience.start_date).toLocaleDateString()}{" "}
                -{" "}
                {experience?.until_now?
                  tn("Until now",lang)
                  :(experience.end_date && new Date(experience.end_date).toLocaleDateString())}
              </p>
            </div>
            <p style={{ fontSize: '14px', color: '#666' }}>
              {experience.company} - {experience.city}, {experience.country}
            </p>
            <div
              dangerouslySetInnerHTML={{ __html: experience.description }}
              style={{ fontSize: '14px', color: '#555' }}
            ></div>
          </div>
        ))}
      {/* </section> */}

      {/* Education Section */}
      {/* <section> */}
      {data.education?.[0] &&  <div style={sectionTitleWithLineStyle(data?.cv_color)}>
        <div style={lineStyle(data?.cv_color)} />

          <h2>{tn('Education', lang)}</h2>
        </div>}
      
        {data.education.map((education_, index) => (
          <div key={index} style={sectionContentStyle}>
            <div style={sectionTitleStyle}>
              <h3 style={{ fontSize: '15px', fontWeight: 'bold' }}>
                {education_.university}
              </h3>
              <p style={{ fontSize: '12px', color: '#999' }}>
                {education_.graduation_date && new Date(education_.graduation_date).toLocaleDateString()}
              </p>
            </div>
            <p style={{ fontSize: '14px', color: '#666' }}>
              {education_.major} - {education_.GPA}
            </p>
          </div>
        ))}
      {/* </section> */}

      {/* Skills Section */}
      {/* <section> */}
       {data.skills?.[0] && <div style={sectionTitleWithLineStyle(data?.cv_color)}>
        <div style={lineStyle(data?.cv_color)} />
          <h2>{tn('Skills', lang)}</h2>
         
        </div>}
        <ul style={{ listStyle: 'none', padding: '0', fontSize: '14px', color: '#555' }}>
          {data.skills.map((skill, index) => (
            <li key={index} style={{ paddingBottom: '8px' }}>
              <span style={{ fontWeight: '600' }}>{skill.skill}</span> - {skill.level}
            </li>
          ))}
        </ul>
      {/* </section> */}

      {/* Certificates Section */}
      {/* <section> */}
       {data.certificates?.[0] && <div style={sectionTitleWithLineStyle(data?.cv_color)}>
        <div style={lineStyle(data?.cv_color)} />

          <h2>{tn('Certificates and Courses', lang)}</h2>
        </div>}
        {data.certificates.map((certificate, index) => (
          <div key={index} style={{ paddingBottom: '10px', color: '#555' }}>
            <p>
              <strong>{certificate.title}</strong> - {certificate.institute}
            </p>
            <p>{certificate.date && new Date(certificate.date).toLocaleDateString()}</p>
          </div>
        ))}
      {/* </section> */}

      {/* Languages Section */}
      {/* <section> */}
       {data.languages?.[0] && <div style={sectionTitleWithLineStyle(data?.cv_color)}>
        <div style={lineStyle(data?.cv_color)} />

          <h2>{tn('Languages', lang)}</h2>
        </div>}
        <ul style={{ fontSize: '14px', color: '#555' }}>
          {data.languages.map((language, index) => (
            <li key={index} style={{ paddingBottom: '8px' }}>
              {language.language} - {language.level}
            </li>
          ))}
        </ul>
      {/* </section> */}
  </A4pages>
  );
};

// Common styles for section titles and content
const sectionTitleWithLineStyle = (color: string) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  paddingTop: '20px',
  paddingBottom: '10px',
  fontSize: '20px',
  fontWeight: 'bold',
});

const lineStyle = (color: string) => ({
  flexGrow: 1,
  height: '2px',
  borderBottom: '4px solid '+color || '#007acc',
  maxWidth: '40px',
});

const sectionContentStyle = {
  paddingBottom: '15px',
  padding: '10px 0',
  borderBottom: '1px solid #eee',
};

const sectionTitleStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const CVtemplate2Layout = ({ data,lang }: any) => {
  return (
    <div    dir={lang === "ar" ? "rtl" : "ltr"}
      style={{
        fontFamily: data?.font_type+", sans-serif" ,
      }}
    >
      {/* Header Bar */}
      {/* <div
        style={{
          border: `5px solid ${data?.cv_color || '#f97316'}`,
        }}
      /> */}

    </div>
  );
};






export const CVtemplate2_content = ({ data, lang,  }: any) => {
  return (
    <>
   
  
      {/* Profile Section */}
      <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '40px' }}>
        {data?.profile_picture && (
          <div
            style={{
              borderRadius: '50%',
              width: '120px',
              minWidth: '120px',
              height: '120px',
              overflow: 'hidden',
              marginRight: lang === "ar" ? '0' : '30px',
              marginLeft: lang === "ar" ? '30px' : '0',
              boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
            }}
          >
            <img
              src={
                typeof data?.profile_picture === "string"
                  ? data?.profile_picture
                  : URL?.createObjectURL(data?.profile_picture)
              }
              alt="Profile"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
        )}
        <div>
          <h1
            style={{
              fontSize: '28px',
              fontWeight: '600',
              color: data?.cv_color || '#007acc',
            }}
          >
            {data.first_name} {data.last_name}
          </h1>
          {/* <p style={{ fontSize: '16px', color: '#777' }}>{data.about}</p> */}
          <div
                dangerouslySetInnerHTML={{ __html: data.career_summary }}
                  style={{  fontSize: '16px', color: '#555' }}
              ></div>
        </div>
      </div>

      {/* Contact Info */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#f1f1f1',
          padding: '10px 20px',
          borderRadius: '8px',
          paddingBottom: '30px',
        }}
      >
        <a href={`mailto:${data.email}`} style={{ color: '#007acc', textDecoration: 'none' }}>
          ✉️ {data.email}
        </a>
        <a href={`tel:${data.mobile}`} style={{ color: '#007acc', textDecoration: 'none' }}>
          📞 {data.mobile}
        </a>
      </div>

      {/* Experience Section */}
      {/* <section> */}
      {data.experiences?.[0] &&  <div style={sectionTitleWithLineStyle(data?.cv_color)}>
      <div style={lineStyle(data?.cv_color)} />

          <h2>
            {/* {tn('Experiences', lang)} */}
            {
              lang == "ar" ? "الخبرات" : "Experiences"
            }
            </h2>
        </div>}
        {data.experiences.map((experience, index) => (
          <div key={index} style={sectionContentStyle}>
            <div style={sectionTitleStyle}>
              <h3 style={{ fontSize: '15px', fontWeight: 'bold' }}>
                {experience.job_title}
              </h3>
              <p style={{ fontSize: '12px', color: '#999' }}>
                {experience.start_date && new Date(experience.start_date).toLocaleDateString()}{" "}
                -{" "}
                {experience?.until_now?
                  // tn("Until now",lang)
                  lang == "ar" ? "حتى الآن" : "Present"
                  :(experience.end_date && new Date(experience.end_date).toLocaleDateString())}
              </p>
            </div>
            <p style={{ fontSize: '14px', color: '#666' }}>
              {experience.company} - {experience.city}, {experience.country}
            </p>
            <div
              dangerouslySetInnerHTML={{ __html: experience.description }}
              style={{ fontSize: '14px', color: '#555' }}
            ></div>
          </div>
        ))}
      {/* </section> */}

      {/* Education Section */}
      {/* <section> */}
      {data.education?.[0] &&  <div style={sectionTitleWithLineStyle(data?.cv_color)}>
        <div style={lineStyle(data?.cv_color)} />

          <h2>
            {/* {tn('Education', lang)} */}
            {
              lang == "ar" ? "التعليم" : "Education"
            }
            </h2>
        </div>}
      
        {data.education.map((education_, index) => (
          <div key={index} style={sectionContentStyle}>
            <div style={sectionTitleStyle}>
              <h3 style={{ fontSize: '15px', fontWeight: 'bold' }}>
                {education_.university}
              </h3>
              <p style={{ fontSize: '12px', color: '#999' }}>
                {education_.graduation_date && new Date(education_.graduation_date).toLocaleDateString()}
              </p>
            </div>
            <p style={{ fontSize: '14px', color: '#666' }}>
              {education_.major} - {education_.GPA}
            </p>
          </div>
        ))}
      {/* </section> */}

      {/* Skills Section */}
      {/* <section> */}
       {data.skills?.[0] && <div style={sectionTitleWithLineStyle(data?.cv_color)}>
        <div style={lineStyle(data?.cv_color)} />
          <h2>
            {/* {tn('Skills', lang)} */}
            {
              lang == "ar" ? "المهارات" : "Skills"
            }
            </h2>
         
        </div>}
        <ul style={{ listStyle: 'none', padding: '0', fontSize: '14px', color: '#555' }}>
          {data.skills.map((skill, index) => (
            <li key={index} style={{ paddingBottom: '8px' }}>
              <span style={{ fontWeight: '600' }}>{skill.skill}</span> - {skill.level}
            </li>
          ))}
        </ul>
      {/* </section> */}

      {/* Certificates Section */}
      {/* <section> */}
       {data.certificates?.[0] && <div style={sectionTitleWithLineStyle(data?.cv_color)}>
        <div style={lineStyle(data?.cv_color)} />

          <h2>{
            // tn('Certificates and Courses', lang)
            }
            {
              lang == "ar" ? "الشهادات والدورات" : "Certificates and Courses"
            }
            </h2>
        </div>}
        {data.certificates.map((certificate, index) => (
          <div key={index} style={{ paddingBottom: '10px', color: '#555' }}>
            <p>
              <strong>{certificate.title}</strong> - {certificate.institute}
            </p>
            <p>{certificate.date && new Date(certificate.date).toLocaleDateString()}</p>
          </div>
        ))}
      {/* </section> */}

      {/* Languages Section */}
      {/* <section> */}
       {data.languages?.[0] && <div style={sectionTitleWithLineStyle(data?.cv_color)}>
        <div style={lineStyle(data?.cv_color)} />

          <h2>
            {/* {tn('Languages', lang)} */}
            {
              lang == "ar" ? "اللغات" : "Languages"
            }
          </h2>
        </div>}
        <ul style={{ fontSize: '14px', color: '#555' }}>
          {data.languages.map((language, index) => (
            <li key={index} style={{ paddingBottom: '8px' }}>
              {language.language} - {language.level}
            </li>
          ))}
        </ul></>
  );
};