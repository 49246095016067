import AItool from "../../../main_components/ai_autofill/AItool";

export const Translator = async ({ lang, content }) => {
  try {
    const res = await AItool({
      prompt: `
      Translate only the values of this JSON to ${lang}, ensuring that the translation makes sense for CV content. Keep the keys intact and return the JSON in the same structure. Ensure the response is a valid and well-formed JSON, with no additional text, prefixes, or suffixes. Focus on translating professional terms logically to fit a CV context, such as job titles, skills, and descriptions.

      Here is the JSON: ${JSON.stringify(content)}
      `,
    });

    const translatedJSON = JSON.parse(res);

    return translatedJSON;
  } catch (error) {
    console.error("Error during translation:", error);
    throw new Error("Translation failed");
  }
};
