import { Avatar, Button, Text } from "@mantine/core";
import { tn } from "../../../../translation";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../context/TranslationContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../constants";
import { AppContext } from "../../../../context/AppContext";

import {
  convertEnglishNumbersToArabic,
  fv,
  vf,
} from "../../../../main_components/Functions";
import { styled } from "styled-components";
import { SegmentedControl } from "@mantine/core";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function PersonalDataView({ setEditing }: any) {
  const { locale }: any = useContext(TranslationContext);

  const [userData] = useState(pb.authStore.model);
  const genders =   vf("gender");
  return (
    <div
      style={{
        width: "100%",
        //   height: "100%",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "10px",
            color: "#5BB9BF",
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => setEditing(true)}
        >
          {svgUpdate}
          {tn("Update")}
        </div>
      <div>
 
      <Width1300
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
            }}
          >
            <Text color="#757575" size="md" fw="500">
              {tn("First Name")}
            </Text>
            <BoxStyleF text={userData?.first_name} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
            }}
          >
            <Text color="#676767" size="md" fw="500">
              {tn("Last Name")}
            </Text>
            <BoxStyleF text={userData?.last_name} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
            }}
          >
            <Text color="#676767" size="md" fw="500">
              {tn("Mobile")}
            </Text>
            <BoxStyleF
              text={
                locale === "ar"
                  ? convertEnglishNumbersToArabic(userData?.mobile)
                  : userData?.mobile
              }
              direction={locale === "ar" ? "ltr" : null}
              textAlign={locale === "ar" ? "right" : null}
              justifyContent={locale === "ar" ? "end" : null}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
            }}
          >
            <Text color="#676767" size="md" fw="500">
              {tn("Email")}
            </Text>
            <BoxStyleF
              text={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {userData?.email}
                </div>
              }
              direction="ltr"
              width="200px"
            />
          </div>
        </div>
      </Width1300>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "10px",
            minWidth: "calc(25% - 10px)",
          }}
        >
          <Text color="#676767" size="md" fw="500">
            {tn("Gender")}
          </Text>
          <SegmentedControl
          // disabled
          value={userData?.gender}
          style={{
            width: "120px",
          }}
              data={genders?.[0] ? genders?.map((item: any) => {
                return {label: item?.[locale], value: item?.name}
              }) : []}
              styles={() => ({
                root: {
                  color: "#737373",
                  backgroundColor: "transparent",
                  border: "1px solid rgba(7, 1, 13, 0.50)",
                  borderRadius: "7px",
                 
                },
                label: {
                  color: "#737373",
                  height: "25px",
                  borderRadius: "7px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 10px",
                  "&:hover": {
                    color: "#737373",
                  },
                  '&[data-active="true"]': {
                    color: "#ffffff",
                  },
                  "&[data-active]:hover": {
                    color: "#ffffff",
                  },
                },
                indicator: {
                  color: "#737373",
                  backgroundColor: "rgba(113, 113, 113, 0.70) !important",
                  borderRadius: "7px",
                },
                control: {
                  width: "100%",
                  color: "#737373",
                  //    backgroundColor: "#efefef5f !important",
                  borderRadius: "7px",
                },
                controlActive: { color: "#737373" },
              })}
              />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "10px",
            width: "100%",  whiteSpace: "pre-wrap",
            fontSize: "14px",
          }}
        >
          <Text color="#676767" size="md" fw="500">
            {tn("Bio")}
          </Text>
              {userData?.about ? userData?.about : tn("nothing")}
        </div>
      </div>
      </div>
     
      
    </div>
  );
}

const BoxStyleF: any = ({
  text,
  direction,
  TextAlign,
  justifyContent,
  width,
}: any) => {
  const boxStyle: any = {
    fontSize: "14px",
    height: "2.875rem",
    color: "#000000",
    minWidth: width ?? "150px",
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    direction: direction ? direction : "",
    textAlign: TextAlign ? TextAlign : "",
    justifyContent: justifyContent ? justifyContent : "",
    padding: "0 10px",
    whiteSpace: "nowrap",
  };
  return <div style={boxStyle}>{text ?? tn("nothing")}</div>;
};
const svgUpdate = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.304 4.84412L17.156 7.69612M7 7.00012H4C3.73478 7.00012 3.48043 7.10547 3.29289 7.29301C3.10536 7.48055 3 7.7349 3 8.00012V18.0001C3 18.2653 3.10536 18.5197 3.29289 18.7072C3.48043 18.8948 3.73478 19.0001 4 19.0001H15C15.2652 19.0001 15.5196 18.8948 15.7071 18.7072C15.8946 18.5197 16 18.2653 16 18.0001V13.5001M18.409 3.59012C18.5964 3.77742 18.745 3.99981 18.8464 4.24457C18.9478 4.48933 19 4.75168 19 5.01662C19 5.28156 18.9478 5.5439 18.8464 5.78866C18.745 6.03343 18.5964 6.25581 18.409 6.44312L11.565 13.2871L8 14.0001L8.713 10.4351L15.557 3.59112C15.7442 3.40365 15.9664 3.25493 16.2111 3.15346C16.4558 3.05199 16.7181 2.99976 16.983 2.99976C17.2479 2.99976 17.5102 3.05199 17.7549 3.15346C17.9996 3.25493 18.2218 3.40365 18.409 3.59112V3.59012Z"
      stroke="#5BB9BF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Width1300 = styled.div`
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;
{
  // {userData?.about ? userData?.about : tn("No Data")}

  /* <Button
onClick={() => setEditing(true)}
color="green"
variant="light"
styles={{
  root: {
    backgroundColor: "rgba(105, 220, 191, 0.10)",
    color: "#69DCBF",
  },
}}
>
{tn("Save")}
</Button>
<Button
onClick={() => setChangePassword(true)}
color="red"
variant="light"
styles={{
  root: {
    backgroundColor: "rgba(249, 131, 124, 0.10)",
    color: "#F9837C",
  },
}}
>
{tn("Cancel")}
</Button> */
}

// styles={() => ({
//   root: {
//     color: "#737373",
//     backgroundColor: "transparent",
//     border: "1px solid rgba(7, 1, 13, 0.50)",
//     borderRadius: "7px",
   
//   },
//   label: {
//     color: "#737373",
//     height: "25px",
//     borderRadius: "7px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     padding: "0px 10px",
//     "&:hover": {
//       color: "#737373",
//     },
//     '&[data-active="true"]': {
//       color: "#ffffff",
//     },
//     "&[data-active]:hover": {
//       color: "#ffffff",
//     },
//   },
//   indicator: {
//     color: "#737373",
//     backgroundColor: "#21033F !important",
//     borderRadius: "7px",
//   },
//   control: {
//     width: "100%",
//     color: "#737373",
//     //    backgroundColor: "#efefef5f !important",
//     borderRadius: "7px",
//   },
//   controlActive: { color: "#737373" },
// })}