import {
  NativeSelect,
  TextInput,
  Accordion,
  ActionIcon,
  Center,
} from "@mantine/core";
import { Icon } from "@iconify/react";
import { tn } from "../../translation";
import { useState } from "react";
import { color } from "framer-motion";
import React from "react";
 const FormBlock = ({
  title,
  name,
  if_exist,
  icon,
  main_values,
  fields,
  addText,
  form,
}: any) => {
  const [current, setCurrent]: any = useState("");

  return (
    <div className="flex flex-col mt-10"
    style={{ color: form.values.cv_color,}}
    >
      <div className="flex justify-start items-center gap-4"
      style={{ 
        // force the icon to be the same color as the text
        color: form.values.cv_color,
      }}
      >
        {icon(form.values.cv_color)}
        <div className="flex items-end gap-1">
          <div className="text-[25px] font-semibold">{title}</div>
        </div>
      </div>
      <Accordion
        value={current}
        onChange={(value_): any => setCurrent(value_)}
        className="mt-4 w-full"
        variant="separated"
        radius={"lg"}
        chevronPosition="left"
        mx="auto"
      >
        {form.values[name]?.[0] &&
          form.values[name]?.map((value, index) => (
            <Accordion.Item value={"i-" + index}>
              <AccordionControl form={form} index={index} name={name}>
                {main_values
                  ?.map((value) => form.values[name]?.[index]?.[value])
                  ?.join(" - ")}
              </AccordionControl>
              <Accordion.Panel>{fields(name, index)}</Accordion.Panel>
            </Accordion.Item>
          ))}
      </Accordion>
      <div className="w-full flex ">
        <div
          className="flex justify-start items-center gap-2 mt-4 text-[#5BB9BF] font-medium cursor-pointer p-1"
          onClick={() => {
            form.setFieldValue(name, [
              ...form.values[name],
              {
                company: "",
              },
            ]);
            setCurrent("i-" + form.values[name].length);
          }}
        >
          <Icon icon="ph:plus-bold"></Icon> {addText}
        </div>
      </div>
    </div>
  );
};

export default React.memo(FormBlock);

function AccordionControl({form,index,name,...props}:any) {
  return (
    <Center>
      <Accordion.Control {...props} />
      {
       index < form.values[name].length - 1 &&
        <ActionIcon
        onClick={() => {
           // move item down
            if (index < form.values[name].length - 1) {
                const copy = [...form.values[name]];
                const temp = copy[index];
                copy[index] = copy[index + 1];
                copy[index + 1] = temp;
                form.setFieldValue(name, copy);
            }
        }}
      size="md" variant="subtle" color="gray" className="text-sm">
        <Icon icon="mingcute:arrow-down-fill" className="text-[14px]" />
      </ActionIcon>}
      {
         index > 0 &&
        <ActionIcon
      onClick={() => {
      // move item up
            if (index > 0) {
                const copy = [...form.values[name]];
                const temp = copy[index];
                copy[index] = copy[index - 1];
                copy[index - 1] = temp;
                form.setFieldValue(name, copy);
            }
      }}
      size="md" variant="subtle" color="gray" className="text-sm">
        <Icon icon="mingcute:arrow-up-fill" className="text-[14px]" />
      </ActionIcon>}
      <ActionIcon
      onClick={() => {
        form.setFieldValue(
          name,
          form.values[name].filter((_, i) => i !== index
            )
            );
      }}
      size="md" variant="subtle" color="gray">
        <Icon icon="mdi:delete-outline" className="text-[14px]" />
      </ActionIcon>
    </Center>
  );
}
