import React, { useState } from "react";
import { tn } from "../../translation";
import { buttons_style } from "../../components_styles";
import { Button } from "@mantine/core";
import { CVtemplate1, CVtemplate1Layout, CVtemplate1_content } from "./cv_templates/CVtemplate1";
import { CVtemplate2, CVtemplate2_content } from "./cv_templates/CVtemplate2";
import { CVtemplate3, CVtemplate3_content } from "./cv_templates/CVtemplate3";
import A4pages from "./components/A4pages";
import { styled } from "styled-components";

const CVviewer = ({ data, lang }) => {
  const [loading, setLoading] = useState(false);
const [count, setCount] = useState(0)
  return (
    <div
      style={{
        backgroundColor: "#f4f4f9",
        padding: "30px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          textAlign: "center",
          marginBottom: "30px",
          width: data?.cv_size === "A4" ? "210mm" : "8.5in",
          // maxWidth: "595px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#f3ec78",
            backgroundImage:
              "linear-gradient(113deg, rgba(105,220,191,1) 0%, rgba(222,140,134,1) 19%, rgba(33,3,63,1) 56%)",
            WebkitBackgroundClip: "text",
            MozBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textAlign: "right",
            fontSize: "42px",
            fontStyle: "normal",
            fontWeight: "700",
            minWidth: "fit-content",
          }}
        >
          {tn("CV", lang)}
        </div>
        <Button
          loading={loading}
          leftIcon={icon1}
          styles={buttons_style("filled-main")}
          onClick={async () => {
            setLoading(true);
            try {
              const htmlContent =
                document.getElementById("template1")?.innerHTML;
              if (!htmlContent) {
                throw new Error("No HTML content found");
              }

              const response = await fetch(
                "https://functions.coophub.co/html_to_pdf",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    html: htmlContent,
                    cv_size: data?.cv_size || "A4",
                  }),
                }
              );

              if (!response.ok) {
                throw new Error("Failed to generate PDF");
              }

              // Download the PDF
              const blob = await response.blob();
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = data?.cv_title || "coophub_cv";
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url); // Clean up the URL once it's no longer needed
              document.body.removeChild(a); // Clean up the <a> element after download
            } catch (error) {
              console.error("Error generating PDF:", error);
            } finally {
              setLoading(false);
            }
            // setCount(count + 1)
          }}
        >
          {tn("Download as PDF", lang)}
        </Button>
      </div>
      {/* <div
        style={{
          backgroundColor: "#fff",
          width: data?.cv_size === "A4" ? "695px" : "742px",
          minHeight: data?.cv_size === "A4" ? "1002px" : "962px",
          margin: "auto",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
          direction: lang === "ar" ? "rtl" : "ltr",
        }}
        className="transform origin-top-left scale-90 max-2xl:scale-75  max-xl:scale-50"
      >
        <div id="template1" dir={lang === "ar" ? "rtl" : "ltr"} style={{ color: "#171717", textAlign: 
          lang === "ar" ? "right" : "left", 
          padding: "5px",
      }}>
          <TemplateSwitcher data={data} lang={lang} />
        </div>
      </div> */}
     
      {/* {[...Array(count)].map((_, index) => (
      <p key={index}>This is line {index + 1}</p>
    ))}
        {...CVtemplate1({data, lang}).map((_, index) => (
         _
        ))}  */}
        {/* <CVtemplate1 data={data} lang={lang} count={count} /> */}
        <TemplateSwitcher data={data} lang={lang} count={count} />
    </div>
  );
};

export default CVviewer;

const TemplateSwitcher = ({ data, lang,count }) => {
  switch (data?.cv_template) {
    case 1:
      return <CVtemplate1 data={data} lang={lang} />;
    case 2:
      return <CVtemplate2 data={data} lang={lang} count={count} />;
    case 3:
      return <CVtemplate3 data={data} lang={lang} count={count} />;
    default:
      return <CVtemplate1 data={data} lang={lang} />;
  }
};

const ContentWrapper = styled.div`
  /* visibility: hidden;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1; */
`;
export const ContentSwitcher = ({ data, lang }) => {
  switch (data?.cv_template) {
    case 1:
      return <ContentWrapper id="template1" ><CVtemplate1_content data={data} lang={lang} /></ContentWrapper>;
    case 2:
      return <ContentWrapper id="template1" ><CVtemplate2_content data={data} lang={lang} /></ContentWrapper>;
    case 3:
      return <ContentWrapper id="template1" ><CVtemplate3_content data={data} lang={lang} /></ContentWrapper>;
    default:
      return <ContentWrapper id="template1" ><CVtemplate1_content data={data} lang={lang} /></ContentWrapper>;
  }
};

        
      
const icon1 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M17.875 2.75H4.125C3.76033 2.75 3.41059 2.89487 3.15273 3.15273C2.89487 3.41059 2.75 3.76033 2.75 4.125V17.875C2.75 18.2397 2.89487 18.5894 3.15273 18.8473C3.41059 19.1051 3.76033 19.25 4.125 19.25H17.875C18.2397 19.25 18.5894 19.1051 18.8473 18.8473C19.1051 18.5894 19.25 18.2397 19.25 17.875V4.125C19.25 3.76033 19.1051 3.41059 18.8473 3.15273C18.5894 2.89487 18.2397 2.75 17.875 2.75ZM17.875 4.125V13.0625H15.4086C15.2281 13.062 15.0493 13.0974 14.8826 13.1666C14.7159 13.2358 14.5646 13.3374 14.4375 13.4655L12.778 15.125H9.22195L7.5625 13.4655C7.43528 13.3373 7.28384 13.2356 7.11699 13.1664C6.95013 13.0973 6.77118 13.0619 6.59055 13.0625H4.125V4.125H17.875ZM17.875 17.875H4.125V14.4375H6.59055L8.25 16.097C8.37722 16.2252 8.52866 16.3269 8.69551 16.3961C8.86237 16.4652 9.04132 16.5006 9.22195 16.5H12.778C12.9587 16.5006 13.1376 16.4652 13.3045 16.3961C13.4713 16.3269 13.6228 16.2252 13.75 16.097L15.4095 14.4375H17.875V17.875ZM7.76359 10.7989C7.63459 10.6699 7.56212 10.4949 7.56212 10.3125C7.56212 10.1301 7.63459 9.9551 7.76359 9.82609C7.8926 9.69709 8.06756 9.62462 8.25 9.62462C8.43244 9.62462 8.6074 9.69709 8.73641 9.82609L10.3125 11.403V6.1875C10.3125 6.00516 10.3849 5.8303 10.5139 5.70136C10.6428 5.57243 10.8177 5.5 11 5.5C11.1823 5.5 11.3572 5.57243 11.4861 5.70136C11.6151 5.8303 11.6875 6.00516 11.6875 6.1875V11.403L13.2636 9.82609C13.3275 9.76222 13.4033 9.71155 13.4868 9.67698C13.5702 9.64241 13.6597 9.62462 13.75 9.62462C13.8403 9.62462 13.9298 9.64241 14.0132 9.67698C14.0967 9.71155 14.1725 9.76222 14.2364 9.82609C14.3003 9.88997 14.351 9.9658 14.3855 10.0493C14.4201 10.1327 14.4379 10.2222 14.4379 10.3125C14.4379 10.4028 14.4201 10.4923 14.3855 10.5757C14.351 10.6592 14.3003 10.735 14.2364 10.7989L11.4864 13.5489C11.4226 13.6128 11.3467 13.6635 11.2633 13.6981C11.1798 13.7327 11.0903 13.7505 11 13.7505C10.9097 13.7505 10.8202 13.7327 10.7367 13.6981C10.6533 13.6635 10.5774 13.6128 10.5136 13.5489L7.76359 10.7989Z"
      fill="white"
    />
  </svg>
);
