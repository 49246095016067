import {  Button, NativeSelect, Text, TextInput, Textarea } from "@mantine/core";
import { tn, vtn } from "../../../../translation";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../context/TranslationContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT, countries_numbers } from "../../../../constants";
import { AppContext } from "../../../../context/AppContext";

import {
  convertArabicNumbersToEnglish,
  convertEnglishNumbersToArabic,
  vf,
} from "../../../../main_components/Functions";
import { styled } from "styled-components";
import { SegmentedControl } from "@mantine/core";
import { useForm } from "@mantine/form";
import { BannersContext } from "../../../../context/BannersContext";
import { IconSquareRoundedCheck } from "@tabler/icons-react";
import { buttons_style, main_input_style, main_no_input_style } from "../../../../components_styles";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function PersonalDataEdit({ setEditing }: any) {

  const [userData, setUserData] = useState(pb.authStore.model);
  //   useEffect(() => {
  //     pb.collection(pb.authStore.model?.collectionName).subscribe(pb.authStore.model.id, (e) => {
  //       pb.authStore.save(pb.authStore.token, e.record);
  //       setUserData(e.record);
  // });
  // return () => {
  //   pb.collection(pb.authStore.model?.collectionName).unsubscribe()
  // };
  //   }, []);
  const [phoneNumber, setPhoneNumber]: any = useState(
    userData?.mobile?.replace(userData?.country_mobile_number, "")
  );
  const validate_text = [
    vtn("case_study"),
    vtn("first_name"),
    vtn("last_name"),
    vtn("taken_email"),
    vtn("email"),
    vtn("password"),
    vtn("password_comfirm"),
    vtn("arabic_name"),
    vtn("mobile"),
    vtn("university"),
    vtn("major"),
    vtn("GPA"),
    vtn("graduation_year"),
    vtn("semester"),
    vtn("degree"),
  ];
  const form = useForm({
    initialValues: {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      gender: userData?.gender,
      mobile: userData?.mobile,
      about: userData?.about,
      country_mobile_number: userData?.country_mobile_number,
    },
    validate: {
      first_name: (value) =>
        !/^\S+ \S+$/.test(value) && value.length > 2 && !/[0-9]/.test(value)
          ? /^[\u0621-\u064A\s]+$/.test(value)
            ? null
            : validate_text?.[7]
          : validate_text?.[1],
      last_name: (value) =>
        /^[\u0621-\u064A\s]+$/.test(value) ? null : validate_text?.[7],
      mobile: (value, values) =>
        value.length == values?.country_mobile_number.length + 9
          ? null
          : validate_text?.[8],
    },
  });

  const { locale }: any = useContext(TranslationContext);
  const { setTopBanner }: any = useContext(BannersContext);
  const { reloader, setReloader }:any = useContext(AppContext);


  const genders = vf("gender");

 
     



  useEffect(() => {
    // if (phoneNumber.length > 0) {
      form.setFieldValue("mobile", "");
    // }
  }, [form.values.country_mobile_number]);
  useEffect(() => {
    // if (phoneNumber.length > 0) {
      form.setFieldValue(
        "mobile",
        form.values.country_mobile_number + phoneNumber
      );
    // } else {
    //   form.setFieldValue("mobile", userData?.mobile);
    // }
  }, [phoneNumber]);
  const dialogMassage = tn("Data has been updated");


  return (
    <form
    onSubmit={form.onSubmit((value) => {
      const data_ = {
        first_name: value?.first_name,
        last_name: value?.last_name,
        gender:
          value?.gender,
        mobile: value?.mobile,
        about: value?.about,
        country_mobile_number: value?.country_mobile_number,
      };

      const record = pb
        .collection("applicants")
        .update(userData?.id, data_)
        .then(async (records: any) => {
          // setBannerOpen(true);
          setTopBanner({
            message: dialogMassage,
            color: "#2DC071",
            top: "83px",
            backgroundColor: "#F2FFF7EB",
            icon: <IconSquareRoundedCheck />,
          });
          setEditing(false);
          const authData = await pb.collection('applicants').authRefresh();
          setReloader(!reloader);
          setUserData(pb?.authStore?.model);
        });
        
    })}
      style={{
        width: "100%",
        //   height: "100%",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "10px",
            color: "#5BB9BF",
            width: "100%",
          }}
        >
        <Button
        type="submit"
          color="green"
          styles={buttons_style("light-green")}
        >
          {tn("Save")}
        </Button>
        <Button
          color="red"
          styles={buttons_style("light-red")}
          onClick={() => setEditing(false)}
        >
          {tn("Cancel")}
          </Button>
        </div>
      <div>
 
      <Width1300
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            width: "100%",
          }}
        >
                <TextInput style={{width:"100%"}}
                label={tn("First Name")}
                  size="xs"
                  {...form.getInputProps("first_name")}
                  styles={main_input_style(form.errors.first_name)}
                />
            <TextInput style={{width:"100%"}}
                label={tn("Last Name")}
                  size="xs"
                  {...form.getInputProps("last_name")}
                  styles={main_input_style(form.errors.last_name)}
                />
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            width: "100%",
          }}
        >
         
            <TextInput style={{width:"100%"}}
              label={tn("Mobile")}
                  // required
                  onChange={(e) => 
                    setPhoneNumber( convertArabicNumbersToEnglish(e.target.value))
                  }
                  value={
                    locale == "ar"?convertEnglishNumbersToArabic(  form.values.mobile?.replace(
                      form.values.country_mobile_number,
                      ""
                    )):
                    form.values.mobile?.replace(
                    form.values.country_mobile_number,
                    ""
                  )}
                  error={form.errors.mobile}
                  rightSection={
                    <NativeSelect
                      {...form.getInputProps("country_mobile_number")}
                      // value={countryNum}
                      data={countries_numbers}
                      styles={(theme) => ({
                        label: {
                          color: "#ffffff",
                        },
                        input: {
                          fontSize: "13px",
                          border: "0px solid #69DCBF",
                          borderRadius: "0px",
                          color: "white",
                          backgroundColor: "transparent",
                          // borderBottom: "1px solid #69dcbf86",
                          "&:focus-within": {
                            // borderBottom: "1px solid #69DCBF",
                          },
                        },
                      
                      })}
                    />
                  }
                  rightSectionWidth={60}
                  withAsterisk={false}
                  size="xs"
                
                  styles={main_input_style(form.errors.mobile)}
                />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
            }}
          >
            <Text color="#676767" size="md" fw="500">
              {tn("Email")}
            </Text>
            <div style={main_no_input_style()}>
            {userData?.email}
            </div>
          </div>
        </div>
      </Width1300>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "10px",
            minWidth: "calc(25% - 10px)",
          }}
        >
          <Text color="#3b3b3b" size="md" fw="500">
            {tn("Gender")}
          </Text>
          <SegmentedControl
          // disabled
          {...form.getInputProps("gender")}
          style={{
            width: "120px",
          }}
              data={genders?.[0] ? genders?.map((item: any) => {
                return {label: item?.[locale], value: item?.name}
              }) : []}
              styles={() => ({
                root: {
                  color: "#737373",
                  backgroundColor: "transparent",
                  border: "1px solid rgba(7, 1, 13, 0.50)",
                  borderRadius: "7px",
                 
                },
                label: {
                  color: "#737373",
                  height: "25px",
                  borderRadius: "7px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 10px",
                  "&:hover": {
                    color: "#737373",
                  },
                  '&[data-active="true"]': {
                    color: "#ffffff",
                  },
                  "&[data-active]:hover": {
                    color: "#ffffff",
                  },
                },
                indicator: {
                  color: "#737373",
                  backgroundColor: "#21033F !important",
                  borderRadius: "7px",
                },
                control: {
                  width: "100%",
                  color: "#737373",
                  //    backgroundColor: "#efefef5f !important",
                  borderRadius: "7px",
                },
                controlActive: { color: "#737373" },
              })}
              />
        </div>
        
          <Textarea style={{width:"100%"}}
          label={tn("Bio")}
                  size="xs"
                 {...form.getInputProps("about")}
                 styles={main_input_style(form.errors.about,{input:{height:"100px"}})}
                />
             
      </div>
      </div>
     
      
    </form>
  );
}


const Width1300 = styled.div`
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

  