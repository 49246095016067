import { Button } from "@mantine/core";
import { tn } from "../../../../translation";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";

export const LangChoose = () => {
    const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between gap-12">
      <div className="flex items-center justify-center gap-2">
        {lang_svg}
        <div className="text-[#21033F] font-semibold p-1">
          {tn("Select language")}
        </div>
      </div>
      <div className="flex items-center justify-center gap-2">
        <Button
          onClick={() => {
            navigate("/applicant/cv_generator/ar");
          }}
          leftIcon={
            <Icon icon="twemoji:flag-saudi-arabia" className="text-[28px]" />
          }
          className="bg-transparent rounded-[10px] border-[1px] border-[#21033F] text-[#21033F]"
        >
          العربية
        </Button>
        <Button
          onClick={() => {
            navigate("/applicant/cv_generator/en");
          }}
          leftIcon={britain}
          className="bg-transparent rounded-[10px] border-[1px] border-[#21033F] text-[#21033F]"
        >
          English
        </Button>
      </div>
    </div>
  );
};
const lang_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 50 50"
    fill="none"
  >
    <path
      d="M4.16602 24.9998C4.16602 36.5057 13.4934 45.8332 24.9994 45.8332C36.5052 45.8332 45.8327 36.5057 45.8327 24.9998C45.8327 13.4939 36.5052 4.1665 24.9994 4.1665C13.4934 4.1665 4.16602 13.4939 4.16602 24.9998Z"
      stroke="#21033F"
      stroke-width="3.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.084 4.26953C27.084 4.26953 33.334 12.5 33.334 24.9999C33.334 37.4999 27.084 45.7305 27.084 45.7305"
      stroke="#21033F"
      stroke-width="3.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.916 45.7305C22.916 45.7305 16.666 37.4999 16.666 24.9999C16.666 12.5 22.916 4.26953 22.916 4.26953"
      stroke="#21033F"
      stroke-width="3.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.47852 32.2915H44.5218"
      stroke="#21033F"
      stroke-width="3.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.47852 17.7085H44.5218"
      stroke="#21033F"
      stroke-width="3.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const britain = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 40 26"
    fill="none"
  >
    <g clip-path="url(#clip0_5673_5374)">
      <path d="M0 0H40V25.6078H0V0Z" fill="#21033F" />
      <path
        d="M4.6875 0L19.9375 9.65628L35.125 0H40V3.30767L25 12.8573L40 22.3535V25.6078H35L20 16.0582L5.0625 25.6078H0V22.4068L14.9375 12.9106L0 3.41437V0H4.6875Z"
        fill="white"
      />
      <path
        d="M26.5 14.9912L40 23.4738V25.6078L23.0625 14.9912H26.5ZM15 16.0582L15.375 17.9255L3.375 25.6078H0L15 16.0582ZM40 0V0.160049L24.4375 10.1898L24.5625 7.84239L36.875 0H40ZM0 0L14.9375 9.38953H11.1875L0 2.24068V0Z"
        fill="#C8102E"
      />
      <path
        d="M15.0625 0V25.6078H25.0625V0H15.0625ZM0 8.53594V17.0719H40V8.53594H0Z"
        fill="white"
      />
      <path
        d="M0 10.2965V15.418H40V10.2965H0ZM17.0625 0V25.6078H23.0625V0H17.0625Z"
        fill="#C8102E"
      />
    </g>
    <defs>
      <clipPath id="clip0_5673_5374">
        <rect width="40" height="25.6078" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
