import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  visibility: hidden;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`;

const BackgroundLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: -1; // Ensure the layout stays behind the content */
`;

const splitComponent = (component, maxHeight) => {
  const componentClone = React.cloneElement(component, {
    style: { ...component.props.style, height: maxHeight, overflow: 'hidden' },
  });
  return [componentClone];
};

const A4pages = ({ children, layout, main_style, data }) => {
  const [pages, setPages] = useState([]);
  const contentRef = useRef(null);
  const {lang} = useParams();
  useEffect(() => {
    if (contentRef.current) {
      const isA4 = data?.cv_size === "A4";
      const A4_HEIGHT = isA4 ? 1122 : 1056; // A4 height: 1122px, Letter height: 1056px
      const topBottomPadding = 30 * 2;
      const availableHeight = A4_HEIGHT - topBottomPadding;

      const newPages = [];
      let currentPage = [];
      let currentHeight = 0;

      const childRefs = Array.from(contentRef.current.children);

      childRefs.forEach((childNode, idx) => {
        const element = React.Children.toArray(children)[idx];
        const childHeight = childNode ? childNode.scrollHeight : 0;

        if (currentHeight + childHeight > availableHeight) {
          if (childHeight > availableHeight) {
            const splitParts = splitComponent(element, availableHeight - currentHeight);
            splitParts.forEach((part) => {
              currentPage.push(part);
              newPages.push([...currentPage]);
              currentPage = [];
              currentHeight = 0;
            });
          } else {
            newPages.push(currentPage);
            currentPage = [];
            currentHeight = 0;
            currentPage.push(element);
            currentHeight += childHeight;
          }
        } else {
          currentPage.push(element);
          currentHeight += childHeight;
        }
      });

      if (currentPage.length > 0) {
        newPages.push(currentPage);
      }

      setPages(newPages);
    }
  }, [children]);

  const Page = styled.div`
    width: ${data?.cv_size === "A4" ? "210mm" : "8.5in"};
    height: ${data?.cv_size === "A4" ? "297mm" : "11in"};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    padding: 30px 20px;
    box-sizing: border-box;
    background-color: white;
    overflow: hidden;
    position: relative;
    z-index: 1;

    @media print {
      page-break-after: always;
    }
  `;

  return (
    <Container >
      <ContentWrapper ref={contentRef}>
        {children}
      </ContentWrapper>
    <div id="template1">
      {pages.map((pageContent, index) => (
       
        <Page
        dir={lang === "ar" ? "rtl" : "ltr"}
        key={index}>
          <BackgroundLayout>{layout}</BackgroundLayout>
          <div style={main_style}>
            {pageContent.map((child, idx) => React.cloneElement(child, { key: idx }))}
          </div>
        
           
         
        </Page>
      ))
      }</div>
    </Container>
  );
};

export default A4pages;
