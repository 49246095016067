import React from 'react';
import { tn } from '../../../translation';
import A4pages from '../components/A4pages';

export const CVtemplate1 = ({ data,lang, }: any) => {
  return (
    <A4pages data={data}  layout={<CVtemplate1Layout data={data} lang={lang} />}> 
  
      {/* Profile Section */}
      
        <div
            style={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            paddingBottom: '30px',
          }}
        >
          {data?.profile_picture && (
            <div     style={{ 
              borderRadius: '50%',
              width: '100px',
              minWidth: '100px',
              height: '100px',
              border: `4px solid ${data?.cv_color || '#f97316'}`,
              backgroundImage: `url(${
                typeof data?.profile_picture == "string"
                  ? data?.profile_picture
                  : URL?.createObjectURL(data?.profile_picture)
              })`,
              backgroundSize: 'cover',
            }}>
            {/* <img
              src={
                typeof data?.profile_picture == "string"
                  ? data?.profile_picture
                  : URL?.createObjectURL(data?.profile_picture)
              }
              alt="Profile"
                style={{ 
                width: '100%',
              }}
            /> */}
            </div>
          )}
          <div>
            <div
                style={{ 
                color: data?.cv_color || '#f97316',
                fontWeight: 'bold',
                fontSize: '26px',
              }}
            >
              {data.first_name} {data.last_name}
            </div>
            <div
                dangerouslySetInnerHTML={{ __html: data.career_summary }}
                  style={{  fontSize: '16px', color: '#555' }}
              ></div>
          </div>
        </div>
  
        {/* Contact Info */}
        <div
            style={{ 
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '20px',
          }}
        >
          <a
            href={`mailto:${data.email}`}
              style={{  fontSize: '14px', color: '#f97316', textDecoration: 'none' }}
          >
            ✉️ {data.email}
          </a>
          <a
            href={`tel:${data.mobile}`}
              style={{  fontSize: '14px', color: '#f97316', textDecoration: 'none' }}
          >
            📞 {data.mobile}
          </a>
        </div>

        {/* Experience Section */}
        {/* <section> */}
         {data.experiences?.[0] && <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              height: 'fit-content',
            }}
          >
            {tn('Experiences', lang)}
          </div>}
          {data.experiences.map((experience, index) => (
            <div key={index}   style={{  paddingBottom: '15px' }}>
              <div
                  style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div   style={{  color: '#333', fontSize: '16px', fontWeight: 'bold' }}>
                  {experience.job_title}
                </div>
                <div   style={{  fontSize: '12px', color: '#666' }}>
                  {experience.start_date &&
                    new Date(experience.start_date).toLocaleDateString()}{" "}
                  -{" "}
                  {experience?.until_now?
                  tn("Until now",lang)
                  :( experience.end_date &&
                    new Date(experience.end_date).toLocaleDateString())}
                </div>
              </div>
              <div   style={{  fontSize: '14px', padding: '5px 0', color: '#444' }}>
                {experience.company} - {experience.city}, {experience.country}
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: experience.description }}
                  style={{  fontSize: '14px', color: '#555' }}
              ></div>
            </div>
          ))}
        {/* </section> */}

        {/* Education Section */}
        {/* <section> */}
        {data.education?.[0] &&  <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              paddingTop: '20px',
            }}
          >
            {tn('Education', lang)}
          </div>}
          {/* <div>
            <div   style={{  fontSize: '16px', fontWeight: 'bold', color: '#333' }}>
              {data.university}
            </div>
            <p   style={{  fontSize: '14px', color: '#444' }}>{data.major}</p>
            <div
                style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '12px',
                color: '#666',
              }}
            >
              <p>{tn("University number",lang)} {data.university_number}</p>
              <p> {tn("GPA",lang)}: {data.GPA}/{data.GPA_out_of}</p>
            </div>
          </div> */}
          {data.education.map((education_, index) => (
            <div key={index}   style={{  paddingBottom: '15px' }}>
              <div
                  style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div   style={{  color: '#333', fontSize: '16px', fontWeight: 'bold' }}>
                  {education_.university}
                </div>
                <p   style={{  fontSize: '12px', color: '#666' }}>
                  {education_.graduation_date &&
                    new Date(education_.graduation_date).toLocaleDateString()}{" "}
                 
                </p>
              </div>
              <p   style={{  fontSize: '14px', padding: '5px 0', color: '#444' }}>
                {education_.major} - {education_.GPA}
              </p>
            </div>
          ))}
        {/* </section> */}

        {/* Certificates Section */}
        {/* <section> */}
         {data.certificates?.[0] && <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              paddingTop: '20px',
            }}
          >
            {tn('Certificates and Courses', lang)}
          </div>}
          {data.certificates.map((certificate, index) => (
            <div key={index}   style={{  fontSize: '14px', color: '#555' }}>
              <p>
                <strong>{certificate.title}</strong> - {certificate.institute}
              </p>
              <p>{certificate.date && new Date(certificate.date).toLocaleDateString()}</p>
            </div>
          ))}
        {/* </section> */}

        {/* Skills Section */}
        {/* <section> */}
         {data.skills?.[0] && <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              paddingTop: '20px',
            }}
          >
            {tn('Skills', lang)}
          </div>}
          <ul   style={{  fontSize: '14px', color: '#555' }}>
            {data.skills.map((skill, index) => (
              <li key={index}>
                {skill.skill} - {skill.level}
              </li>
            ))}
          </ul>
        {/* </section> */}

        {/* Languages Section */}
        {/* <section> */}
        {data.languages?.[0] &&  <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              paddingTop: '20px',
            }}
          >
            {tn('Languages', lang)}
          </div>}
          <ul   style={{  fontSize: '14px', color: '#555' }}>
            {data.languages.map((language, index) => (
              <li key={index}>
                {language.language} - {language.level}
              </li>
            ))}
          </ul>
        {/* </section> */}
        </A4pages>
  );
};

export const CVtemplate1Layout = ({ data,lang }: any) => {
  return (
    <div    dir={lang === "ar" ? "rtl" : "ltr"}
        style={{ 
        fontFamily: data?.font_type+", sans-serif" ,
      }}
    >
      {/* Header Bar */}
      <div
          style={{ 
          border: `5px solid ${data?.cv_color || '#f97316'}`,
        }}
      />

    </div>
  );
};

export const CVtemplate1_content = ({ data,lang, }: any) => {
  return ( <><div
            style={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            paddingBottom: '30px',
          }}
        >
          {data?.profile_picture && (
            <div     style={{ 
              borderRadius: '50%',
              width: '100px',
              minWidth: '100px',
              height: '100px',
              border: `4px solid ${data?.cv_color || '#f97316'}`,
              backgroundImage: `url(${
                typeof data?.profile_picture == "string"
                  ? data?.profile_picture
                  : URL?.createObjectURL(data?.profile_picture)
              })`,
              backgroundSize: 'cover',
            }}>
           
            </div>
          )}
          <div>
            <div
                style={{ 
                color: data?.cv_color || '#f97316',
                fontWeight: 'bold',
                fontSize: '26px',
              }}
            >
              {data.first_name} {data.last_name}
            </div>
            <div
                dangerouslySetInnerHTML={{ __html: data.career_summary }}
                  style={{  fontSize: '16px', color: '#555' }}
              ></div>
          </div>
        </div>
  
        <div
            style={{ 
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '20px',
          }}
        >
          <a
            href={`mailto:${data.email}`}
              style={{  fontSize: '14px', color: '#f97316', textDecoration: 'none' }}
          >
            ✉️ {data.email}
          </a>
          <a
            href={`tel:${data.mobile}`}
              style={{  fontSize: '14px', color: '#f97316', textDecoration: 'none' }}
          >
            📞 {data.mobile}
          </a>
        </div>

        {/* Experience Section */}
        {/* <section> */}
         {data.experiences?.[0] && <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              height: 'fit-content',
            }}
          >
           {
            lang === "ar" ? "الخبرات" : "Experiences"
           }
          </div>}
          {data.experiences.map((experience, index) => (
            <div key={index}   style={{  paddingBottom: '15px' }}>
              <div
                  style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div   style={{  color: '#333', fontSize: '16px', fontWeight: 'bold' }}>
                  {experience.job_title}
                </div>
                <p   style={{  fontSize: '12px', color: '#666' }}>
                  {experience.start_date &&
                    new Date(experience.start_date).toLocaleDateString()}{" "}
                  -{" "}
                  {experience?.until_now?
                  lang === "ar" ? "حتى الآن" : "Present"
                  :( experience.end_date &&
                    new Date(experience.end_date).toLocaleDateString())}
                </p>
              </div>
              <p   style={{  fontSize: '14px', padding: '5px 0', color: '#444' }}>
                {experience.company} - {experience.city}, {experience.country}
              </p>
              <div
                dangerouslySetInnerHTML={{ __html: experience.description }}
                  style={{  fontSize: '14px', color: '#555' }}
              ></div>
            </div>
          ))}
        {/* </section> */}

        {/* Education Section */}
        {/* <section> */}
        {data.education?.[0] &&  <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              paddingTop: '20px',
            }}
          >
            {/* {tn('Education', lang)} */}
            {
            lang === "ar" ? "التعليم" : "Education"
            }
          </div>}
          {/* <div>
            <div   style={{  fontSize: '16px', fontWeight: 'bold', color: '#333' }}>
              {data.university}
            </div>
            <p   style={{  fontSize: '14px', color: '#444' }}>{data.major}</p>
            <div
                style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '12px',
                color: '#666',
              }}
            >
              <p>{tn("University number",lang)} {data.university_number}</p>
              <p> {tn("GPA",lang)}: {data.GPA}/{data.GPA_out_of}</p>
            </div>
          </div> */}
          {data.education.map((education_, index) => (
            <div key={index}   style={{  paddingBottom: '15px' }}>
              <div
                  style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div   style={{  color: '#333', fontSize: '16px', fontWeight: 'bold' }}>
                  {education_.university}
                </div>
                <p   style={{  fontSize: '12px', color: '#666' }}>
                  {education_.graduation_date &&
                    new Date(education_.graduation_date).toLocaleDateString()}{" "}
                 
                </p>
              </div>
              <p   style={{  fontSize: '14px', padding: '5px 0', color: '#444' }}>
                {education_.major} - {education_.GPA}
              </p>
            </div>
          ))}
        {/* </section> */}

        {/* Certificates Section */}
        {/* <section> */}
         {data.certificates?.[0] && <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              paddingTop: '20px',
            }}
          >
            {/* {tn('Certificates and Courses', lang)} */}
            {
            lang === "ar" ? "الشهادات والدورات" : "Certificates and Courses"
            }
          </div>}
          {data.certificates.map((certificate, index) => (
            <div key={index}   style={{  fontSize: '14px', color: '#555' }}>
              <p>
                <strong>{certificate.title}</strong> - {certificate.institute}
              </p>
              <p>{certificate.date && new Date(certificate.date).toLocaleDateString()}</p>
            </div>
          ))}
        {/* </section> */}

        {/* Skills Section */}
        {/* <section> */}
         {data.skills?.[0] && <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              paddingTop: '20px',
            }}
          >
            {/* {tn('Skills', lang)} */}
            {
            lang === "ar" ? "المهارات" : "Skills"
            }
          </div>}
          <ul   style={{  fontSize: '14px', color: '#555' }}>
            {data.skills.map((skill, index) => (
              <li key={index}>
                {skill.skill} - {skill.level}
              </li>
            ))}
          </ul>
        {/* </section> */}

        {/* Languages Section */}
        {/* <section> */}
        {data.languages?.[0] &&  <div
              style={{ 
              fontWeight: 'bold',
              fontSize: '20px',
              color: data?.cv_color || '#f97316',
              borderBottom: `2px solid ${data?.cv_color || '#f97316'}`,
              paddingBottom: '5px',
              paddingTop: '20px',
            }}
          >
            {/* {tn('Languages', lang)} */}
            {
            lang === "ar" ? "اللغات" : "Languages"
            }
          </div>}
          <ul   style={{  fontSize: '14px', color: '#555' }}>
            {data.languages.map((language, index) => (
              <li key={index}>
                {language.language} - {language.level}
              </li>
            ))}
          </ul></>
  );
};
