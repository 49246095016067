import { Button, Modal, Textarea } from "@mantine/core";
import { tn } from "../../translation";
import { useContext, useState } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { buttons_style, main_input_style } from "../../components_styles";
import AItool from "./AItool";
import Handlebars from "handlebars";
export const AIautofill = ({ ai_autofill,user_data,old_text,form,name ,lang}: any) => {
  const { translation,locale }: any = useContext(TranslationContext);
  const current = translation?.ai_autofill?.find(
    (item: any) => item?.name == ai_autofill
  );
  const [AImodal, setAImodal] = useState(false)
  const common = current?.["common_prompts_"+(lang||locale)]?.split("\n");
  const [inputData, setInputData] = useState("")
  const [AIres, setAIres] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Button
      onClick={()=>{
        setAImodal(true);
      }}
        size="sm"
        styles={{
          root: {
            backgroundImage:
              "linear-gradient(90deg, rgba(62,9,115,1) 0%, rgba(105,220,191,1) 100%)",
            backgroundSize: "120%",
            backgroundPosition: "center",
            padding: "2px",
            borderRadius: "13px",
          },
        }}
      >
        <div className="bg-white w-full h-full rounded-[10px] flex items-center justify-center p-2">
          <div
            style={{
              background: "linear-gradient(90deg, #3E0973 0%, #69DCBF 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              height: "15px",
            }}
          >
            {tn("Create using Ai",lang)}
          </div>
        </div>
      </Button>
      <Modal
              opened={!AIres && !!AImodal } onClose={()=>{
                setAImodal(false);
                setAIres("");
                setLoading(false)
              }}
                size="md"
                padding="20px"
                style={{ zIndex: 10001, position: "relative" }}
                overlayProps={{
                  zIndex: 10001,
                  opacity: 0.55,
                  blur: 3,
                }}
                styles={() => ({
                  content: {
                    zIndex: 10001,
                    borderRadius: "20px",
                    background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
                  },
                  header: { display: "none" },
                  body: { padding: "9px" },
                  inner: { zIndex: 10001 },
                })}
                centered
              >
                <div
                  style={{
                    background: "white",
                    width: "100%",
                    height: "100%",
                    marginTop: "9px",
                    borderRadius: "10px",
                    // paddingTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                    gap: "10px",
                    padding: "20px",
                    position: "relative",
                  }}
                >
                    <div className="flex items-center gap-2 text-[#474747] text-[20px]">
                    <Icon icon={"mingcute:ai-line"} />
                    <div >Ai</div>
                    </div>
                    <Textarea
                    onChange={(e) => {
                        setInputData(e.target.value)
                    }}
                    value={inputData}
                    className="w-full"
                        placeholder={tn("Type here what you want the AI ​​to do",lang)}
                        styles={main_input_style(false,{
                            input:{
                                height: "180px",
                            }
                        })}
                    />

                    <div className="overflow-y-scroll h-[160px]  scrollbar-hide">
                        {common?.map((item: any, index: number) => (
                            <div 
                            onClick={()=>{
                                setInputData(inputData + (inputData?.length > 0 ?"\n" :"")+ item)
                            }}
                            key={index} className="w-fit cursor-pointer flex items-center gap-2 rounded-full border-[1px] py-1 px-2 my-2 border-[#07010d7f] border-solid text-[#07010d7f] text-[14px]">
                                <div>{item}</div>
                                </div>
                        ))}
                        <div className="h-[50px]" />
                    </div>
                    <div className="absolute bottom-2 right-0 w-full flex items-center px-2 pt-8" style={{
                        background: "linear-gradient(0deg, rgba(255,255,255,1) 70%, rgba(105,220,191,0) 100%)"

                    }}>
                        <Button
                        loading={loading}
                        onClick={async ()=>{
                            setLoading(true)
                           const res = await AItool({
                                prompt:Handlebars.compile(current?.["system_prompt_"+(lang||locale)])({
                                    user_request:inputData,
                                    user_data:user_data,
                                    old_text:old_text
                                  }),
                            });
                            setAIres(res);
                            setAImodal(false);
                            setLoading(false)
                        }}
                        leftIcon={ <Icon icon={"mingcute:ai-line"} className="text-[22px]"/>}
                        styles={buttons_style("filled-main")} size="lg">
                            {tn("Create text",lang)}
                        </Button>
                        
                    </div>
                </div>
        
      </Modal>
      <Modal
              opened={!!AIres && !AImodal } onClose={()=>{
                setAImodal(false);
                setAIres("");
                setLoading(false)
              }}
                size="md"
                padding="20px"
                style={{ zIndex: 10001, position: "relative" }}
                overlayProps={{
                  zIndex: 10001,
                  opacity: 0.55,
                  blur: 3,
                }}
                styles={() => ({
                  content: {
                    zIndex: 10001,
                    borderRadius: "20px",
                    background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
                  },
                  header: { display: "none" },
                  body: { padding: "9px" },
                  inner: { zIndex: 10001 },
                })}
                centered
              >
                <div
                  style={{
                    background: "white",
                    width: "100%",
                    height: "100%",
                    marginTop: "9px",
                    borderRadius: "10px",
                    // paddingTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                    gap: "10px",
                    padding: "20px",
                    position: "relative",
                  }}
                >
                    <div className="flex items-center gap-2 text-[#474747] text-[20px]">
                    <Icon icon={"mingcute:ai-line"} />
                    <div >Ai</div>
                    </div>
                  <div className="overflow-y-scroll h-[260px] px-2  scrollbar-hide text-[#07010d7f] text-center pb-[60px]">
                    {AIres}
                  </div>
                    <div className="absolute bottom-2 right-0 w-full flex items-center px-2 gap-2 pt-8" style={{
                        background: "linear-gradient(0deg, rgba(255,255,255,1) 70%, rgba(105,220,191,0) 100%)"

                    }}>
                        <Button
                        className="w-full"
                        loading={loading}
                        onClick={async ()=>{
                            form.setFieldValue(name,AIres)
                            setAImodal(false);
                            setAIres("");
                            setLoading(false)
                        }}
                        leftIcon={icon1}
                        styles={buttons_style("filled-green")} size="lg">
                            {tn("Accept",lang)}
                        </Button>
                        <Button
                          className="w-full"
                        loading={loading}
                        onClick={async ()=>{
                            setAImodal(true);
                            setAIres("");
                            setLoading(false)
                        }}
                        leftIcon={icon2}
                        styles={buttons_style("outline-red")} size="lg">
                            {tn("Back",lang)}
                        </Button>
                        
                    </div>
                </div>
        
      </Modal>
    </div>
  );
};


const icon1=<svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
<path d="M1.85547 8.45384L6.82795 13.4263C7.57251 14.1709 8.79241 14.1274 9.48203 13.3317L19.9993 1.19629" stroke="white" stroke-width="1.81439" stroke-linecap="round"/>
</svg>

const icon2 = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
<path d="M21.7575 14.4045C21.7557 16.1019 21.1248 17.7292 20.003 18.9294C18.8813 20.1296 17.3604 20.8046 15.774 20.8065H7.54669C7.34832 20.8065 7.15808 20.7222 7.01782 20.5721C6.87755 20.422 6.79875 20.2185 6.79875 20.0063C6.79875 19.794 6.87755 19.5905 7.01782 19.4404C7.15808 19.2903 7.34832 19.206 7.54669 19.206H15.774C16.9642 19.206 18.1056 18.7001 18.9472 17.7997C19.7888 16.8992 20.2616 15.678 20.2616 14.4045C20.2616 13.1311 19.7888 11.9098 18.9472 11.0094C18.1056 10.1089 16.9642 9.60308 15.774 9.60308H4.8644L8.07585 13.0381C8.2162 13.1883 8.29504 13.3919 8.29504 13.6043C8.29504 13.8167 8.2162 14.0203 8.07585 14.1705C7.93551 14.3206 7.74516 14.405 7.54669 14.405C7.34821 14.405 7.15787 14.3206 7.01752 14.1705L2.52991 9.36901C2.46037 9.29469 2.4052 9.20643 2.36756 9.10928C2.32992 9.01213 2.31055 8.908 2.31055 8.80284C2.31055 8.69767 2.32992 8.59354 2.36756 8.49639C2.4052 8.39924 2.46037 8.31098 2.52991 8.23666L7.01752 3.4352C7.15787 3.28504 7.34821 3.20068 7.54669 3.20068C7.74516 3.20068 7.93551 3.28504 8.07585 3.4352C8.2162 3.58536 8.29504 3.78902 8.29504 4.00137C8.29504 4.21373 8.2162 4.41739 8.07585 4.56754L4.8644 8.00259H15.774C17.3604 8.00445 18.8813 8.67953 20.003 9.87973C21.1248 11.0799 21.7557 12.7072 21.7575 14.4045Z" fill="#F9837C"/>
</svg>